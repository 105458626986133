import { differenceInWeeks, parseISO } from "date-fns";

import { PatientCheckInData } from "~/common/utils";

export const getWeeksText = (
  bundleStartDateText: string,
  checkin: PatientCheckInData,
) => {
  const bundleStartDate = parseISO(bundleStartDateText);
  const checkinStartDate = parseISO(checkin.start_date);
  const weeksDifference = differenceInWeeks(checkinStartDate, bundleStartDate);

  if (weeksDifference <= 2) {
    return "Two weeks check";
  }
  if (weeksDifference <= 4) {
    return "Four weeks check";
  }
  if (weeksDifference <= 8) {
    return "Eight weeks check";
  }
  return checkin.description;
};
