import { useQuery } from "@tanstack/react-query";

import { getPatientCheckIn } from "~/providers/api";

interface UseGetPatientCheckInsProps {
  patientId: string;
  patientCheckInId?: string;
  enabled?: boolean;
}

export const useGetPatientCheckin = ({
  patientId,
  patientCheckInId,
  enabled = true,
}: UseGetPatientCheckInsProps) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getPatientCheckIn(patientId, patientCheckInId ?? ""),
    queryKey: ["getPatientInteraction", patientId, patientCheckInId],
    enabled: !!patientCheckInId && enabled,
  });
  return { data, isLoading };
};
