import { Script } from "~/common/types/scriptsTypes";
import { formatMedicationNameWithStrength } from "~/common/utils";
import { PrescriptionRequestRowDetails } from "~/providers/components/prescriptionRequest/PrescriptionRequestRow";
import { OWNER_OPTIONS } from "~/providers/constants";
import { formatDate } from "../orders";

export const formatPrescriptionRequestData = (
  data: Script,
): PrescriptionRequestRowDetails => {
  const { patient_medication: patientMedication } = data;
  const medication = patientMedication.medication;
  const medicationStrength =
    patientMedication.patient_medications_details[0].medication_strength;
  const lastScriptStatusChange = data.patient_medication.scripts_statuses?.[0];

  return {
    id: data.id,
    patientId: data.patient.id,
    patientName: `${data.patient.name} ${data.patient.last_name}`,
    medication: formatMedicationNameWithStrength(
      medication,
      medicationStrength,
    ),
    createdDate: formatDate(data.created_at),
    owner: data.owner ? `${data.owner.id}` : OWNER_OPTIONS.unassigned,
    preAuthorized: data.pre_authorization_request,
    status: data.status,
    providerApproval: !!lastScriptStatusChange?.is_paused,
  };
};
