import { useQuery } from "@tanstack/react-query";

import { getConsultationReport } from "~/providers/api/consultationReport";

export const useGetConsultationReport = (
  patientId: string,
  enabled?: boolean,
) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getConsultationReport(patientId),
    queryKey: ["getConsultationReport", patientId],
    enabled,
  });

  return { data, isLoading };
};
