import { generatePath } from "react-router-dom";

import { ConsultationIcon, OrderNoteIcon } from "~/common/components";
import {
  MedicationInteractionsIcon,
  PrescriptionRequestIcon,
} from "~/common/components/icons/Orders";
import { formatMedicationName } from "~/common/utils";
import {
  PatientMedicationDetailsDrawerProps,
  SelectedPatientCheckin,
} from "~/providers/components";
import { ACTIVITY_FEED_CODES, RELATED_MODEL } from "~/providers/constants";
import { ROUTES } from "~/providers/router";
import {
  getCheckinSubmittedSeeDetails,
  getCheckinSubmittedTitle,
  getFeedDescription,
  getIconByCode,
} from "~/providers/utils";
import { PatientFeed } from "~/providers/utils/types/interactions";
import {
  BundleFeed,
  CheckInOrderedFeed,
  CheckInSubmittedFeed,
  FeedData,
  FeedSeeDetails,
  MedicationFeed,
  MedicationFeedContext,
  NoteFeed,
  PrescriptionFeed,
  PrescriptionFeedContext,
  RecurrentCheckInOrderedFeed,
  SELECTED_PATIENT_CHECKIN_TYPE,
} from "~/providers/utils/types/patientFeeds";

export const getMedicationFeedInternalNote = (feed: MedicationFeed) =>
  feed.context.data?.internal_note;

interface UseGetActivityFeedCardDataProps {
  viewPatientCheckInDetails: (
    selectedPatientCheckinInfo: SelectedPatientCheckin,
  ) => void;
  viewMedicationDetails: (
    medication: PatientMedicationDetailsDrawerProps,
  ) => void;
}

export const useGetActivityFeedCardData = ({
  viewPatientCheckInDetails,
  viewMedicationDetails,
}: UseGetActivityFeedCardDataProps) => {
  const getPrescriptionSeeDetails = (
    relatedModelId: string,
  ): FeedSeeDetails => ({
    handleSeeDetails: () => {
      const url = `${ROUTES.prescriptionRequest}?id=${relatedModelId}`;
      window.open(url, "_blank");
    },
    seeDetailsDescription: "Go to Request",
  });

  const getMedicationSeeDetails = (context: MedicationFeedContext) => ({
    handleSeeDetails: () =>
      viewMedicationDetails({
        medication: {
          medication_detail: context.data,
          created_at: context.data.created_at,
        },
        medicationName: formatMedicationName(context.medication),
      }),
    seeDetailsDescription: "See details",
  });

  const getProviderPrescriptionSeeDetails = (
    context: PrescriptionFeedContext,
  ) => ({
    handleSeeDetails: () =>
      viewMedicationDetails({
        medication: {
          medication_detail: context.data.patient_medications_details[0],
          created_at: context.data.patient_medications_details[0].created_at,
        },
        medicationName: formatMedicationName(context.data.medication),
      }),
    seeDetailsDescription: "See details",
  });

  const getOldFeedData = (feed: PatientFeed): FeedData | undefined => {
    const data = {
      [RELATED_MODEL.patient_checkin_schedule]: {
        title: feed.description,
        icon: getIconByCode(
          (feed as CheckInSubmittedFeed).context.checkin_code,
        ),
      },
      [RELATED_MODEL.patient_bundle]: {
        title: feed.description,
        icon:
          "bundle" in feed.context
            ? getIconByCode(
                feed.context.bundle.patient_checkins[0].checkin.code,
              )
            : undefined,
      },
      [RELATED_MODEL.patient_medication]: {
        title: feed.description,
        icon: MedicationInteractionsIcon,
      },
      [RELATED_MODEL.script_request]: {
        title: feed.description,
        icon: PrescriptionRequestIcon,
        seeDetails: getPrescriptionSeeDetails(feed.related_model_id),
      },
      [RELATED_MODEL.note]: {
        title: feed.description,
        icon: () => OrderNoteIcon({}),
      },
      [RELATED_MODEL.patient]: {
        title: feed.description,
        icon: ConsultationIcon,
      },
      [RELATED_MODEL.patient_checkin]: {
        title: feed.description,
        icon: PrescriptionRequestIcon,
      },
    };
    return data[feed.related_model_type];
  };

  const getPrescriptionInternalNote = (feed: PrescriptionFeed) =>
    feed.context.internal_note;

  const getSeeConsultation = (id: string) => ({
    handleSeeDetails: () => {
      const url = generatePath(ROUTES.consultationOverview, {
        patientId: id,
      });
      window.open(url, "_blank");
    },
    seeDetailsDescription: "Go to consultation",
  });

  const getFeedData = (feed: PatientFeed): FeedData | undefined => {
    if (!feed.code) return getOldFeedData(feed);

    const activityFeedCardDataByCode = {
      [ACTIVITY_FEED_CODES.consultation_requested]: {
        title: "Consultation requested",
        icon: ConsultationIcon,
        seeDetails: getSeeConsultation(feed.user_id),
      },
      [ACTIVITY_FEED_CODES.referred_after_screening]: {
        title: "Consultation closed",
        icon: ConsultationIcon,
        seeDetails: getSeeConsultation(feed.user_id),
      },
      [ACTIVITY_FEED_CODES.patient_archived]: {
        title: "Consultation closed",
        icon: ConsultationIcon,
        seeDetails: getSeeConsultation(feed.user_id),
      },
      [ACTIVITY_FEED_CODES.consultation_completed]: {
        title: "Consultation completed",
        icon: ConsultationIcon,
        seeDetails: getSeeConsultation(feed.user_id),
      },
      [ACTIVITY_FEED_CODES.checkin_ordered]: {
        title: "Ordered",
        icon: getIconByCode((feed as CheckInOrderedFeed).context.checkin_code),
        internalNote: (feed as CheckInOrderedFeed).context.data?.internal_note,
        seeDetails: {
          handleSeeDetails: () =>
            viewPatientCheckInDetails({
              feed: feed as CheckInOrderedFeed,
              isProviderOrdered: true,
              type: SELECTED_PATIENT_CHECKIN_TYPE.regular,
            }),
          seeDetailsDescription: "See details",
        },
      },
      [ACTIVITY_FEED_CODES.checkin_missed]: {
        title: "Missed",
        icon: getIconByCode((feed as CheckInOrderedFeed).context.checkin_code),
      },
      [ACTIVITY_FEED_CODES.medication_added]: {
        title: "Medication added",
        icon: MedicationInteractionsIcon,
        internalNote: getMedicationFeedInternalNote(feed as MedicationFeed),
        seeDetails: getMedicationSeeDetails((feed as MedicationFeed).context),
      },
      [ACTIVITY_FEED_CODES.medication_modified]: {
        title: "Medication modified",
        icon: MedicationInteractionsIcon,
        internalNote: getMedicationFeedInternalNote(feed as MedicationFeed),
        seeDetails: getMedicationSeeDetails((feed as MedicationFeed).context),
      },
      [ACTIVITY_FEED_CODES.prescription_paused]: {
        title: "Prescriptions paused",
        icon: PrescriptionRequestIcon,
        internalNote: getPrescriptionInternalNote(feed as PrescriptionFeed),
        seeDetails: getProviderPrescriptionSeeDetails(
          feed.context as PrescriptionFeedContext,
        ),
      },
      [ACTIVITY_FEED_CODES.prescription_resumed]: {
        title: "Prescriptions resumed",
        icon: PrescriptionRequestIcon,
        internalNote: getPrescriptionInternalNote(feed as PrescriptionFeed),
        seeDetails: getProviderPrescriptionSeeDetails(
          feed.context as PrescriptionFeedContext,
        ),
      },
      [ACTIVITY_FEED_CODES.medication_discontinued]: {
        title: "Medication discontinued",
        icon: MedicationInteractionsIcon,
        internalNote: getMedicationFeedInternalNote(feed as MedicationFeed),
        seeDetails: getMedicationSeeDetails((feed as MedicationFeed).context),
      },
      [ACTIVITY_FEED_CODES.note_added]: {
        title: "Note added",
        internalNote: (feed as NoteFeed).context.note,
        icon: () => OrderNoteIcon({}),
      },
      [ACTIVITY_FEED_CODES.prescription_requested]: {
        title: "Prescription requested",
        icon: PrescriptionRequestIcon,
        seeDetails: getPrescriptionSeeDetails(feed.related_model_id),
      },
      [ACTIVITY_FEED_CODES.prescription_cancelled]: {
        title: "Prescription request cancelled",
        icon: PrescriptionRequestIcon,
        seeDetails: getPrescriptionSeeDetails(feed.related_model_id),
      },
      [ACTIVITY_FEED_CODES.prescription_sent_to_pharmacy]: {
        title: "Prescription sent to Pharmacy",
        icon: PrescriptionRequestIcon,
        seeDetails: getPrescriptionSeeDetails(feed.related_model_id),
      },
      [ACTIVITY_FEED_CODES.prescription_issue_reported]: {
        title: "Prescription issue reported",
        icon: PrescriptionRequestIcon,
        seeDetails: getPrescriptionSeeDetails(feed.related_model_id),
      },
      [ACTIVITY_FEED_CODES.checkin_submitted]: {
        get title() {
          return getCheckinSubmittedTitle(feed as CheckInSubmittedFeed);
        },
        icon: getIconByCode(
          (feed as CheckInSubmittedFeed).context.checkin_code,
          (feed as CheckInSubmittedFeed).context.creation_type,
        ),
        seeDetails: getCheckinSubmittedSeeDetails(
          feed as CheckInSubmittedFeed,
          viewPatientCheckInDetails,
        ),
      },
      [ACTIVITY_FEED_CODES.bundle_ordered]: {
        title: "Ordered",
        icon: () =>
          getIconByCode(
            (feed as BundleFeed).context.bundle.patient_checkins[0].checkin
              .code,
          )(),
        seeDetails: {
          handleSeeDetails: () =>
            viewPatientCheckInDetails({
              feed: feed as BundleFeed,
              isProviderOrdered: true,
              type: SELECTED_PATIENT_CHECKIN_TYPE.bundle,
            }),
          seeDetailsDescription: "See details",
        },
      },
      [ACTIVITY_FEED_CODES.recurrent_checkin_ordered]: {
        title: "Ordered",
        icon: getIconByCode(
          (feed as RecurrentCheckInOrderedFeed).context.checkin_code,
        ),
        internalNote: (feed as CheckInOrderedFeed).context.data?.internal_note,
        seeDetails: {
          handleSeeDetails: () =>
            viewPatientCheckInDetails({
              feed: feed as RecurrentCheckInOrderedFeed,
              isProviderOrdered: true,
              type: SELECTED_PATIENT_CHECKIN_TYPE.scheduled,
            }),
          seeDetailsDescription: "See details",
        },
      },
      [ACTIVITY_FEED_CODES.ongoing_care]: undefined,
      [ACTIVITY_FEED_CODES.screening_started]: undefined,
      [ACTIVITY_FEED_CODES.screening_submitted]: undefined,
      [ACTIVITY_FEED_CODES.screening_completed]: undefined,
      [ACTIVITY_FEED_CODES.intake_started]: undefined,
      [ACTIVITY_FEED_CODES.intake_submitted]: undefined,
      [ACTIVITY_FEED_CODES.checkin_deleted]: undefined,
      [ACTIVITY_FEED_CODES.patient_created]: undefined,
      [ACTIVITY_FEED_CODES.appointment_scheduled]: undefined,
    } as const;

    const cardData = activityFeedCardDataByCode[feed.code];

    if (!cardData) return undefined;

    return {
      ...cardData,
      description: getFeedDescription(feed),
    };
  };

  return { getFeedData };
};
