import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { UpdateTeamMemberData, updateTeamMemberData } from "~/providers/api";

export const useUpdateTeamMemberDetails = (onSuccess: () => void) => {
  const { mutate: updateTeamMemberDataMutation, isPending } = useMutation({
    mutationFn: (data: UpdateTeamMemberData) => updateTeamMemberData(data),
    onError: () =>
      toast.error(
        "There was an issue updating your information. Please try again later.",
      ),
    onSuccess,
  });

  return { updateTeamMemberDataMutation, isPending };
};
