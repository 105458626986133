import {
  BasicLabsIcon,
  HealthFactorsIcon,
  ScheduleAppointmentIcon,
  SideEffectsIcon,
  StatusCheckDarkIcon,
  StatusCheckIcon,
  WeightIcon,
} from "~/common/components/icons";
import {
  CHECK_IN_CODES,
  CheckInCodes,
  INTERACTION_CREATION_TYPES,
  InteractionCreationTypes,
} from "~/common/utils/types/checkInTypes";

export const getIconByCode = (
  code: CheckInCodes,
  creationType?: InteractionCreationTypes,
) => {
  const isPatientCreation =
    creationType === INTERACTION_CREATION_TYPES.patient_created;

  const statusCheckIcon = isPatientCreation
    ? StatusCheckDarkIcon
    : StatusCheckIcon;

  const lookup = {
    [CHECK_IN_CODES.antidepressants_side_effects]: SideEffectsIcon,
    [CHECK_IN_CODES.benzodiazepines_side_effects]: SideEffectsIcon,
    [CHECK_IN_CODES.psychostimulants_side_effects]: SideEffectsIcon,
    [CHECK_IN_CODES.weight]: WeightIcon,
    [CHECK_IN_CODES.basic_labs]: BasicLabsIcon,
    [CHECK_IN_CODES.blood_pressure_heart_rate]: HealthFactorsIcon,
    [CHECK_IN_CODES.blood_pressure_heart_rate_recurrent]: HealthFactorsIcon,
    [CHECK_IN_CODES.status_check]: statusCheckIcon,
    [CHECK_IN_CODES.schedule_appointment]: ScheduleAppointmentIcon,
  };

  return lookup[code];
};
