import { ConfirmActionModal, H2 } from "~/common/components";
import { TrashIcon } from "~/common/components/icons";
import { Button, Drawer, NoteData } from "~/common/components/ui";
import { CHECK_IN_CODES } from "~/common/utils";
import { checkInUtilities } from "~/common/utils/checkIn/checkInUtilities.ts";
import { OrderDescriptionLayout } from "~/providers/components/patientProfile/orderPanel/OrderDescriptionLayout";
import { checkInBasicData } from "~/providers/constants";
import { useIsTeamMemberStaff } from "~/providers/hooks";
import { orderTypeIcon } from "~/providers/utils/orders";
import { formatDate } from "~/providers/utils/orders/getDateText";
import { ActivityFeedOrder } from "~/providers/utils/types/interactions.ts";
import { ActivityFeedOrderCheckinsDetails } from "./ActivityFeedOrderCheckinsDetails";

interface ActivityFeedOrderDetailsDrawerProps {
  handleClose: () => void;
  allowDeleteOrder: boolean;
  showDeleteOrderModal?: boolean;
  toggleDeleteOrderModal?: () => void;
  handleDelete?: () => void;
  loadingDelete?: boolean;
  activityFeedOrder?: ActivityFeedOrder;
}

export const ActivityFeedOrderDetailsDrawer = ({
  handleClose,
  handleDelete,
  allowDeleteOrder,
  showDeleteOrderModal,
  toggleDeleteOrderModal,
  activityFeedOrder,
  loadingDelete = false,
}: ActivityFeedOrderDetailsDrawerProps) => {
  const { isStaff } = useIsTeamMemberStaff();
  const { formatCheckInCode } = checkInUtilities();

  const formatCheckinCode = formatCheckInCode(activityFeedOrder?.description);

  const isScheduleAppointment =
    activityFeedOrder?.checkins?.[0].code ===
    CHECK_IN_CODES.schedule_appointment;

  return (
    <Drawer isOpen={Boolean(activityFeedOrder)} onClose={handleClose}>
      {activityFeedOrder && (
        <div className="flex h-full flex-col gap-6 divide-y divide-neutral-700 overflow-y-auto">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <p className="text-sm text-gray-700">
                Assigned on {formatDate(activityFeedOrder.created_at)}
              </p>
              {formatCheckinCode && (
                <div className="flex flex-row items-center gap-2">
                  {!isScheduleAppointment &&
                    orderTypeIcon[activityFeedOrder.type]}
                  <H2>{formatCheckinCode}</H2>
                </div>
              )}
            </div>
            <OrderDescriptionLayout
              {...checkInBasicData[activityFeedOrder.description]}
            />
          </div>
          <ActivityFeedOrderCheckinsDetails
            activityFeedOrder={activityFeedOrder}
          />
          <div className="flex h-full w-full flex-col items-end justify-between">
            <div className="flex w-full flex-col items-start gap-6 pt-4">
              {activityFeedOrder?.note && (
                <NoteData
                  seeMoreButton
                  title="Message for patient"
                  note={activityFeedOrder.note}
                />
              )}
              {activityFeedOrder?.internal_note && (
                <NoteData
                  seeMoreButton
                  title="Internal note"
                  note={activityFeedOrder.internal_note}
                />
              )}
            </div>
            {!isStaff && allowDeleteOrder && (
              <Button
                variant="outline-black"
                size="sm"
                className="w-fit"
                onClick={toggleDeleteOrderModal}
                isLoading={loadingDelete}
              >
                <TrashIcon />
                Delete order
              </Button>
            )}
          </div>
        </div>
      )}
      <ConfirmActionModal
        title="Delete order"
        isLoading={loadingDelete}
        description="Are you sure you want to delete this order?"
        show={Boolean(showDeleteOrderModal)}
        onClose={() => toggleDeleteOrderModal?.()}
        onConfirm={handleDelete}
      />
    </Drawer>
  );
};
