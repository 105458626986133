import { PropsWithChildren, ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";

import { ChevronIcon, Typography } from "~/common/components";
import { DIRECTIONS } from "~/common/constants";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";

interface ItemCardLayoutProps extends PropsWithChildren {
  title: ReactNode;
  subtitle?: string;
  description?: string;
  additionalActions?: ReactNode;
  containerClassName?: string;
  badge?: ReactNode;
}

export const ItemCardLayout = ({
  additionalActions,
  subtitle,
  title,
  children,
  description,
  containerClassName,
  badge,
}: ItemCardLayoutProps) => {
  const [seeDetails, setSeeDetails] = useState(false);

  const toggleSeeDetails = () => setSeeDetails((prev) => !prev);

  return (
    <div
      className={twMerge(
        "flex flex-col gap-4 rounded-lg border border-primary-200 bg-primary-50 text-gray-70 transition-all duration-500",
        !seeDetails && "gap-0",
        containerClassName,
      )}
    >
      <div
        className={twMerge(
          "flex w-full items-center justify-between p-4 transition-all duration-300",
          seeDetails && "pb-0",
        )}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            toggleSeeDetails();
          }
        }}
        onClick={toggleSeeDetails}
        aria-label="See details"
        aria-expanded={seeDetails}
      >
        <div className="flex flex-col items-start gap-1">
          <Typography className="text-sm font-medium tracking-wide text-gray-10">
            {subtitle}
          </Typography>
          <div className="flex flex-row items-center gap-2">
            <Typography className="text-lg font-semibold capitalize">
              {title}
            </Typography>
            {badge}
          </div>
          <Typography
            variant={TYPOGRAPHY_VARIANTS.italic}
            className="text-sm italic tracking-wide text-gray-30"
          >
            {description}
          </Typography>
        </div>
        <div className="flex items-center gap-3">
          {additionalActions}
          <ChevronIcon
            direction={seeDetails ? DIRECTIONS.top : DIRECTIONS.down}
            className="h-3.5 text-gray-70 transition-transform duration-300"
          />
        </div>
      </div>
      <div
        className={twMerge(
          "overflow-hidden transition-all duration-300",
          seeDetails ? "max-h-fit p-4 pt-0 opacity-100" : "max-h-0 opacity-0",
        )}
      >
        {children}
      </div>
    </div>
  );
};
