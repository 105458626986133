import { BundlesStatus, LeadPatientStatus } from "~/common/constants";
import {
  MedicationBasicData,
  PatientMedicationDetail,
  PatientMedicationInformation,
} from "~/common/types";
import { Script } from "~/common/types/scriptsTypes";
import {
  CheckInStatuses,
  ExtraData,
  InterfaceWithId,
  Intervals,
  PatientBasicData,
  PatientCheckInData,
} from "~/common/utils";
import {
  AnswerValueType,
  CheckInCodes,
  CheckinStatus,
  InteractionCreationTypes,
} from "~/common/utils/types/checkInTypes";
import {
  ACTIVITY_FEED_CODES,
  RELATED_MODEL,
} from "~/providers/constants/activityFeed";
import { PatientData } from "../types";
import { FeedQuestion } from "./interactions";

export interface BasePatientFeed {
  user_id: string;
  provider_id: string;
  related_model_id: string;
  description: string;
  id: string;
  created_at: string;
  reviewed_at?: string;
  patient_generated: boolean;
  generated_by_name: string;
}

export interface PatientInContextFeed {
  id: string;
  name: string;
  last_name: string;
  email: string;
  status: LeadPatientStatus;
  archived_at: string;
  is_reviewed: boolean;
}

export interface RecurrentCheckInOrderedFeedContextData {
  checkin: PatientCheckInData;
  id: string;
  start_date: string;
  end_date?: string;
  frequency: number;
  note?: string;
  internal_note?: string;
  interval: Intervals;
  last_ran?: string;
  next_interaction_due_date: string;
  patient: PatientData;
  patient_bundle_id?: string;
  created_at: string;
}

export interface CheckInFeedContextData {
  id: string;
  code: CheckInCodes;
  extra_data: ExtraData;
  description: CheckInCodes;
  type: InteractionCreationTypes;
  due_date: string;
  next_interaction_due_date: string;
  end_date?: string;
  created_at: string;
  start_date: string;
  frequency?: number;
  checkin: PatientCheckInData;
  note?: string;
  internal_note?: string;
  creation_type: InteractionCreationTypes;
  patient: PatientInContextFeed;
  status: CheckInStatuses;
  expiration_date: string;
  completed_at: string;
}
export interface CheckInOrderedFeedContext {
  checkin_code: CheckInCodes;
  status: CheckinStatus;
  data: CheckInFeedContextData;
}

export interface CheckInOrderedFeed extends BasePatientFeed {
  related_model_type: typeof RELATED_MODEL.patient_checkin;
  code: typeof ACTIVITY_FEED_CODES.checkin_ordered;
  context: CheckInOrderedFeedContext;
}

export interface CheckInSubmittedFeedContextAnswersData {
  value: AnswerValueType;
  question_id: string;
  presigned_url: string | null;
  input_id: string;
  id: string;
  question?: FeedQuestion;
  internal_note?: string;
}

export interface CheckInSubmittedFeedContextData {
  answers: CheckInSubmittedFeedContextAnswersData[];
  extra_data?: ExtraData;
}

export interface CheckInSubmittedFeedContext {
  creation_type: InteractionCreationTypes;
  data: CheckInSubmittedFeedContextData;
  checkin_code: CheckInCodes;
  status: CheckinStatus;
  internal_note?: string;
  note?: string;
}

export interface CheckInSubmittedFeed extends BasePatientFeed {
  related_model_type: typeof RELATED_MODEL.patient_checkin;
  code:
    | typeof ACTIVITY_FEED_CODES.checkin_submitted
    | typeof ACTIVITY_FEED_CODES.checkin_missed;
  context: CheckInSubmittedFeedContext;
}

export interface MedicationFeedContext {
  data: PatientMedicationDetail;
  medication: MedicationBasicData;
}

export interface MedicationFeed extends BasePatientFeed {
  related_model_type: typeof RELATED_MODEL.patient_medication;
  code:
    | typeof ACTIVITY_FEED_CODES.medication_added
    | typeof ACTIVITY_FEED_CODES.medication_modified
    | typeof ACTIVITY_FEED_CODES.medication_discontinued;
  context: MedicationFeedContext;
}

export interface PrescriptionFeedContextData {
  medication: MedicationBasicData;
  patient_medications_details: PatientMedicationDetail[];
}

export interface PrescriptionFeedContext {
  data: PrescriptionFeedContextData;
  internal_note: string;
}

export interface PrescriptionFeed extends BasePatientFeed {
  related_model_type: typeof RELATED_MODEL.patient_medication;
  code:
    | typeof ACTIVITY_FEED_CODES.prescription_paused
    | typeof ACTIVITY_FEED_CODES.prescription_resumed;
  context: PrescriptionFeedContext;
}

export interface ScriptFeedContext {
  data: Script;
}

export interface ScriptFeed extends BasePatientFeed {
  related_model_type: typeof RELATED_MODEL.script_request;
  code:
    | typeof ACTIVITY_FEED_CODES.prescription_cancelled
    | typeof ACTIVITY_FEED_CODES.prescription_requested
    | typeof ACTIVITY_FEED_CODES.prescription_sent_to_pharmacy;
  context: ScriptFeedContext;
}

export interface NoteFeedContext {
  note: string;
}

export interface NoteFeed extends BasePatientFeed {
  related_model_type: typeof RELATED_MODEL.note;
  code: typeof ACTIVITY_FEED_CODES.note_added;
  context: NoteFeedContext;
}

export interface ConsultationFeed extends BasePatientFeed {
  related_model_type: typeof RELATED_MODEL.patient;
  code:
    | typeof ACTIVITY_FEED_CODES.ongoing_care
    | typeof ACTIVITY_FEED_CODES.screening_completed
    | typeof ACTIVITY_FEED_CODES.consultation_requested
    | typeof ACTIVITY_FEED_CODES.referred_after_screening
    | typeof ACTIVITY_FEED_CODES.patient_archived;
  context: PatientBasicData;
}

export interface BundleContextData extends InterfaceWithId {
  patient_checkins: PatientCheckInData[];
  patient_medication: PatientMedicationInformation;
}

export interface BundleContext {
  bundle: BundleContextData;
  status: BundlesStatus;
}

export interface BundleFeed extends BasePatientFeed {
  related_model_type: typeof RELATED_MODEL.patient_bundle;
  code: typeof ACTIVITY_FEED_CODES.bundle_ordered;
  context: BundleContext;
}
export interface RecurrentCheckInContext {
  data: RecurrentCheckInOrderedFeedContextData;
  checkin_code: CheckInCodes;
  status: CheckinStatus;
}

export interface RecurrentCheckInOrderedFeed extends BasePatientFeed {
  related_model_type: typeof RELATED_MODEL.patient_checkin_schedule;
  code: typeof ACTIVITY_FEED_CODES.recurrent_checkin_ordered;
  context: RecurrentCheckInContext;
}

export type PatientContext =
  | CheckInOrderedFeedContext
  | CheckInSubmittedFeedContext
  | MedicationFeedContext
  | PrescriptionFeedContext
  | ScriptFeedContext
  | NoteFeedContext
  | BundleContext
  | RecurrentCheckInContext;

export interface FeedSeeDetails {
  handleSeeDetails: () => void;
  seeDetailsDescription: string;
}
export interface FeedData {
  title: string;
  description?: string;
  icon?: () => React.JSX.Element;
  linkDescription?: string;
  linkTo?: string;
  internalNote?: string;
  seeDetails?: FeedSeeDetails;
}

export const SELECTED_PATIENT_CHECKIN_TYPE = {
  scheduled: "scheduled",
  regular: "regular",
  bundle: "bundle",
};

export type SelectedPatientCheckinType =
  (typeof SELECTED_PATIENT_CHECKIN_TYPE)[keyof typeof SELECTED_PATIENT_CHECKIN_TYPE];
