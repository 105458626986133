import { twMerge } from "tailwind-merge";

import { TableData } from "~/common/components/table/TableData";
import { PatientMedicationScriptsStatus } from "~/common/types";
import { MedicationStatusBadge } from "./MedicationStatusBadge";

interface MedicationsTableRowProps {
  date: string;
  strength: string;
  take: string;
  frequency: string;
  handleSeeDetails?: () => void;
  status?: PatientMedicationScriptsStatus;
}

export const MedicationsTableRow = ({
  date,
  strength,
  take,
  frequency,
  handleSeeDetails,
  status,
}: MedicationsTableRowProps) => {
  const { is_paused: isPaused } = { ...status };
  const tableDataClassName = twMerge("py-4", isPaused && "text-gray-20");

  return (
    <tr className="border-b border-gray-300 py-4 last:border-none">
      <TableData className="flex flex-row items-center gap-3 py-4">
        <p>{date}</p>
      </TableData>
      <TableData className={tableDataClassName}>
        {status && <MedicationStatusBadge isPaused={!!isPaused} />}
      </TableData>
      <TableData className={tableDataClassName}>{strength}</TableData>
      <TableData className={tableDataClassName}>{take}</TableData>
      <TableData className={tableDataClassName}>{frequency}</TableData>
      <TableData className={twMerge("text-end", tableDataClassName)}>
        <button
          className="font-medium text-primary-600 underline underline-offset-4"
          onClick={handleSeeDetails}
        >
          See details
        </button>
      </TableData>
    </tr>
  );
};
