import { getWeeksText } from "~/providers/utils";
import { formatDate } from "~/providers/utils/orders/getDateText";
import { ActivityFeedOrder } from "~/providers/utils/types/interactions";

interface ActivityFeedBundleCheckinsDataProps {
  activityFeedOrder: ActivityFeedOrder;
}

export const ActivityFeedBundleCheckinsData = ({
  activityFeedOrder,
}: ActivityFeedBundleCheckinsDataProps) => {
  const firstCheckinStartDate = activityFeedOrder?.checkins[0]?.start_date;
  return (
    <div className="flex flex-col gap-4 pt-6">
      {activityFeedOrder?.checkins.map((checkin) => {
        const description = getWeeksText(firstCheckinStartDate, checkin);
        return (
          <div key={checkin.id} className="flex flex-row gap-2">
            <p className="font-light text-gray-10">{description}</p>
            <p>{formatDate(checkin.due_date)}</p>
          </div>
        );
      })}
    </div>
  );
};
