import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { LeadPatientStatus } from "~/common/constants";
import { useHandleMultipleFilters } from "~/common/hooks";
import { PATIENT_FILTER_PARAMS } from "../constants";
import {
  ENGAGEMENT_OPTIONS,
  EngagementTypes,
  PATIENT_STATUS_BASIC_OPTIONS,
} from "../constants/patientSearchConstants";

export const NONE = "none" as const;

export const useHandlePatientSearchFilters = () => {
  const [params, setParams] = useSearchParams();
  const paramName = params.get(PATIENT_FILTER_PARAMS.name) ?? undefined;
  const [nameFilter, setNameFilter] = useState<string | undefined>(paramName);
  const { handleMultipleFilters } = useHandleMultipleFilters();

  const handlePatientSearchFilters = (filters: string[]) => {
    const engagementFiltersValues = filters.filter((filter) =>
      ENGAGEMENT_OPTIONS.includes(filter as EngagementTypes),
    );

    const engagementFilters = engagementFiltersValues.length
      ? engagementFiltersValues
      : [NONE];

    const statusFilters = filters.filter((filter) =>
      PATIENT_STATUS_BASIC_OPTIONS.includes(filter as LeadPatientStatus),
    );

    handleMultipleFilters(
      PATIENT_FILTER_PARAMS.patientEngagement,
      engagementFilters,
    );

    handleMultipleFilters(PATIENT_FILTER_PARAMS.patientStatus, statusFilters);
  };

  const currentEngagementFilters = params.getAll(
    PATIENT_FILTER_PARAMS.patientEngagement,
  );

  const currentStatusesFilters = params.getAll(
    PATIENT_FILTER_PARAMS.patientStatus,
  );

  const currentFilters = [
    ...currentEngagementFilters,
    ...currentStatusesFilters,
  ];

  const multiSelectGroupValues = [
    ...currentEngagementFilters,
    ...currentStatusesFilters,
  ];

  const handleNameFilterChange = (value?: string) => {
    setNameFilter(value);

    const newParams = new URLSearchParams(params);
    if (value) {
      newParams.set(PATIENT_FILTER_PARAMS.name, value);
      newParams.delete(PATIENT_FILTER_PARAMS.page);
    } else {
      newParams.delete(PATIENT_FILTER_PARAMS.name);
    }
    setParams(newParams);
  };

  return {
    handlePatientSearchFilters,
    multiSelectGroupValues,
    handleNameFilterChange,
    nameFilter,
    currentFilters,
  };
};
