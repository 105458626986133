import { Typography } from "~/common/components";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";

interface InternalNoteProps {
  text: string;
}
export const InternalNote = ({ text }: InternalNoteProps) => {
  return (
    <div className="pt-4">
      <Typography
        variant={TYPOGRAPHY_VARIANTS.italic}
        className="whitespace-pre-wrap break-all rounded-md bg-neutral-100 p-4 font-normal first-letter:uppercase"
      >
        {text}
      </Typography>
    </div>
  );
};
