import { Button } from "~/common/components";
import { PATIENT_MEDICATION_STATUS } from "~/common/constants";
import { PatientMedicationStatus } from "~/common/types";

interface MedicationActionsProps {
  isStaff: boolean;
  handleModify: () => void;
  handleDiscontinue: () => void;
  status: PatientMedicationStatus;
  areButtonsDisabled?: boolean;
}

export const MedicationActions = ({
  handleDiscontinue,
  handleModify,
  isStaff,
  areButtonsDisabled,
  status,
}: MedicationActionsProps) =>
  status === PATIENT_MEDICATION_STATUS.active && (
    <div className="flex items-center gap-4">
      {!isStaff && (
        <div className="flex gap-2">
          <Button
            variant="outline-black"
            size="sm"
            className="bg-neutral-25"
            onClick={(e) => {
              e.stopPropagation();
              handleDiscontinue();
            }}
            disabled={areButtonsDisabled}
          >
            Discontinue
          </Button>
          <Button
            variant="outline-black"
            size="sm"
            className="bg-neutral-25"
            onClick={(e) => {
              e.stopPropagation();
              handleModify();
            }}
            disabled={areButtonsDisabled}
          >
            Modify
          </Button>
        </div>
      )}
    </div>
  );
