import { CHECK_IN_CODES } from "~/common/utils/types/checkInTypes";
import { SelectedPatientCheckin } from "../components";
import {
  CheckInSubmittedFeed,
  SELECTED_PATIENT_CHECKIN_TYPE,
} from "./types/patientFeeds";

export const getCheckinSubmittedTitle = (feed: CheckInSubmittedFeed): string =>
  feed.context.checkin_code === CHECK_IN_CODES.schedule_appointment
    ? `Appointment scheduled (${feed.context.data.extra_data?.duration})`
    : "Submitted";

export const getCheckinSubmittedSeeDetails = (
  feed: CheckInSubmittedFeed,
  viewPatientCheckInDetails: (
    selectedPatientCheckinInfo: SelectedPatientCheckin,
  ) => void,
) =>
  !(feed.context.checkin_code === CHECK_IN_CODES.schedule_appointment)
    ? {
        handleSeeDetails: () =>
          viewPatientCheckInDetails({
            feed: feed,
            isProviderOrdered: false,
            type: SELECTED_PATIENT_CHECKIN_TYPE.regular,
          }),
        seeDetailsDescription: "See details",
      }
    : undefined;
