import { useParams } from "react-router-dom";

import {
  PatientOverviewCurrentMedications,
  PatientOverviewFunctionalDomains,
  PatientOverviewSelfReportedScores,
  PatientOverviewSignificantClinicalDimension,
  PatientOverviewTextInfo,
} from "~/providers/components/patientOverview";
import { PatientOverviewSymptomProgression } from "~/providers/components/patientOverview/PatientOverviewSymptomProgression";
import { useGetPatientInteractionsActions } from "~/providers/hooks";
import { getConsultationSummaryData, PatientParams } from "~/providers/utils";
import { PatientIntakeConsultationSummary } from "~/providers/utils/types/patientIntakeOverviewTypes";
import {
  PatientScreeningConsultationSummary,
  SUMMARY_TYPE,
} from "~/providers/utils/types/patientOverviewTypes";

interface PatientOverviewSectionsProps {
  screeningSummary: PatientScreeningConsultationSummary;
  intakeSummary?: PatientIntakeConsultationSummary;
}

export const PatientOverviewSections = ({
  screeningSummary,
  intakeSummary,
}: PatientOverviewSectionsProps) => {
  const { patientId } = useParams<keyof PatientParams>() as PatientParams;

  const {
    screeningSummaryId,
    intakeSummaryId,
    isSummaryLiked,
    isTreatmentRecommendationsLiked,
    longSummary,
    significantClinicalDimensions,
    medications,
    scores,
    symptomProgression,
    functionalDomains,
    treatmentRecommendations,
  } = getConsultationSummaryData({ screeningSummary, intakeSummary });

  const { showRateButtons, summaryType, handleRateSummary, isRatePending } =
    useGetPatientInteractionsActions({
      patientId,
      screeningSummaryId,
      intakeSummaryId,
    });

  const {
    potential_areas_of_concern: potentialAreasOfConcern,
    no_signs_of_concern: noSignsOfConcern,
  } = significantClinicalDimensions;

  return (
    <div className="flex flex-col gap-6 pt-6">
      <PatientOverviewTextInfo
        showRateButtons={showRateButtons}
        title="Case summary"
        info={longSummary}
        summaryType={summaryType}
        onRate={handleRateSummary}
        isLike={isSummaryLiked}
        isRatePending={isRatePending}
      />

      {medications && (
        <PatientOverviewCurrentMedications medications={medications} />
      )}

      {symptomProgression && (
        <PatientOverviewSymptomProgression
          symptomProgression={symptomProgression}
        />
      )}

      {functionalDomains && (
        <PatientOverviewFunctionalDomains
          functionalDomains={functionalDomains}
        />
      )}

      {treatmentRecommendations && (
        <PatientOverviewTextInfo
          showRateButtons={showRateButtons}
          title="Treatment recommendations"
          info={treatmentRecommendations}
          summaryType={SUMMARY_TYPE.treatment_recommendations}
          onRate={handleRateSummary}
          isLike={isTreatmentRecommendationsLiked}
          isRatePending={isRatePending}
        />
      )}

      <PatientOverviewSignificantClinicalDimension
        potentialAreasOfConcern={potentialAreasOfConcern}
        noSignsOfConcern={noSignsOfConcern}
      />

      <PatientOverviewSelfReportedScores scores={scores} />
    </div>
  );
};
