import { useParams } from "react-router-dom";

import { LoadingState } from "~/common/components";
import { Note } from "~/common/components/ui";
import { useUpdateNoteMutation } from "~/providers/hooks/queries/patientInformation/useUpdateNoteMutation";
import { useGetPatientCarePlansList } from "~/providers/hooks/queries/teamMemberPaymentAndCarePlans/myCarePlans/useGetPatientCarePlansList";
import { getPatientInfoCards } from "~/providers/utils/GetPatientInfoCards";
import { PatientData, PatientParams } from "~/providers/utils/types";
import { CurrentCarePlan } from "./CurrentCarePlan";
import InfoCard from "./InfoCard";

interface PatientDetailsProps {
  patient: PatientData;
}

export const PatientDetails = ({ patient }: PatientDetailsProps) => {
  const infoCards = getPatientInfoCards(patient);
  const { updateNoteMutation } = useUpdateNoteMutation(patient.id);

  const updateNote = async (note: string | null) => {
    await updateNoteMutation({ content: note });
  };

  const { patientId } = useParams<keyof PatientParams>() as PatientParams;

  const { data: carePlans, isLoading } = useGetPatientCarePlansList(patientId);

  if (isLoading) return <LoadingState />;

  return (
    <div className="flex w-full flex-col gap-4 px-14 py-8">
      <Note content={patient.note?.content} handleSubmitNote={updateNote} />
      {carePlans && <CurrentCarePlan carePlans={carePlans} />}
      {infoCards.map((card) => (
        <InfoCard {...card} key={card.title} />
      ))}
    </div>
  );
};
