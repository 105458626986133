import { replaceUnderscores } from "~/common/utils";
import {
  BasicPatientInformation,
  PatientConsultationReportData,
} from "~/providers/utils/types/patientConsultationReportTypes";
import {
  CompletedConsultationFieldLayout,
  CONSULTATION_FIELD_LAYOUT_VARIANTS,
} from "../completeAndSign";
import { BasicPatientInformationReport } from "./BasicPatientInformationReport";

interface SubmittedConsultationReportDataRendererProps {
  data: PatientConsultationReportData;
}

export const SubmittedConsultationReportDataRenderer = ({
  data,
}: SubmittedConsultationReportDataRendererProps) => {
  const ignoredKeys = [
    "id",
    "completed_at",
    "ai_response",
    "consultation_id",
    "past_medical_history",
  ];

  return Object.entries(data).map(([key, value]) => {
    if (key === "basic_patient_information") {
      return (
        <CompletedConsultationFieldLayout
          title="Basic Patient Information"
          key={key}
        >
          <BasicPatientInformationReport
            data={value as BasicPatientInformation}
          />
        </CompletedConsultationFieldLayout>
      );
    }

    if (key === "mental_status_examination") {
      return (
        <CompletedConsultationFieldLayout
          key={key}
          title="Mental status examination"
        >
          <div className="section-content children-section space-y-3">
            {Object.entries(data.mental_status_examination).map(
              ([key, value]: [string, string]) => (
                <CompletedConsultationFieldLayout
                  key={key}
                  title={replaceUnderscores(key)}
                  description={value}
                  variant={CONSULTATION_FIELD_LAYOUT_VARIANTS.secondary}
                />
              ),
            )}
          </div>
        </CompletedConsultationFieldLayout>
      );
    }

    if (key === "past_psychiatric_history") {
      return (
        <CompletedConsultationFieldLayout
          title={"Past Health History"}
          key={key}
        >
          <div className="section-content children-section flex w-full flex-col gap-3">
            <CompletedConsultationFieldLayout
              title="Psychiatric History"
              description={data.past_psychiatric_history}
              variant={CONSULTATION_FIELD_LAYOUT_VARIANTS.secondary}
            />
            <CompletedConsultationFieldLayout
              title="Medical History"
              description={data.past_medical_history}
              variant={CONSULTATION_FIELD_LAYOUT_VARIANTS.secondary}
            />
          </div>
        </CompletedConsultationFieldLayout>
      );
    }
    if (typeof value === "string" && !ignoredKeys.includes(key)) {
      return (
        <CompletedConsultationFieldLayout
          title={replaceUnderscores(key)}
          description={value}
          key={key}
        />
      );
    }
  });
};
