import { twMerge } from "tailwind-merge";

interface ExpandCollapseButtonsProps {
  isExpandedAll: boolean;
  expandAll: (expand: boolean) => void;
}
export const ExpandCollapseButtons = ({
  isExpandedAll,
  expandAll,
}: ExpandCollapseButtonsProps) => (
  <div className="flex items-center justify-end gap-2">
    <button
      onClick={() => expandAll(false)}
      className={twMerge(!isExpandedAll && "underline")}
    >
      Collapse all
    </button>
    <p>/</p>
    <button
      onClick={() => expandAll(true)}
      className={twMerge(isExpandedAll && "underline")}
    >
      Expand all
    </button>
  </div>
);
