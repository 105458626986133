import { formatDate } from "~/providers/utils/orders/getDateText";
import { Order } from "~/providers/utils/types/interactions";

interface ActivityFeedSpecialRequestCheckinDataProps {
  activityFeedOrder: Order;
}
export const ActivityFeedSpecialRequestCheckinData = ({
  activityFeedOrder,
}: ActivityFeedSpecialRequestCheckinDataProps) => {
  const duration = activityFeedOrder.extra_data?.duration;
  return (
    <div>
      {duration && (
        <div className="flex flex-row gap-2 pt-6">
          <p className="w-1/3 font-light text-gray-10">Appointment duration</p>
          <p>{duration}</p>
        </div>
      )}
      <div className="flex flex-row gap-2 pt-6">
        <p className="w-1/3 font-light text-gray-10">
          {duration ? "Due date" : "Check-in due"}
        </p>
        <p>{formatDate(activityFeedOrder.due_date)}</p>
      </div>
    </div>
  );
};
