import { DeepPartial } from "react-hook-form";

import { getPatientBaseUrl } from "../utils";
import {
  PatientConsultationReport,
  PatientConsultationReportData,
} from "../utils/types/patientConsultationReportTypes";
import { api } from "./axios";

const getConsultationReportUrl = (patientId: string) =>
  `${getPatientBaseUrl(patientId)}/consultations/reports`;

export const getConsultationReport = async (patientId: string) => {
  const { data } = await api.get<PatientConsultationReport | null>(
    getConsultationReportUrl(patientId),
  );

  return data;
};

export const updateConsultationReport = async (
  patientId: string,
  consultationReport: DeepPartial<PatientConsultationReportData>,
) => {
  const { data } = await api.put<PatientConsultationReportData>(
    getConsultationReportUrl(patientId),
    consultationReport,
  );
  return data;
};

export const completeConsultationReport = async (
  patientId: string,
  consultationReport: PatientConsultationReportData,
) => {
  const { data } = await api.post<PatientConsultationReportData>(
    getConsultationReportUrl(patientId),
    consultationReport,
  );
  return data;
};
