import { twMerge } from "tailwind-merge";

import { Styled } from "~/common/utils";

interface MedicationStatusBadgeProps extends Styled {
  isPaused: boolean;
}

export const MedicationStatusBadge = ({
  isPaused,
  className,
}: MedicationStatusBadgeProps) => {
  return (
    <div
      className={twMerge(
        "h-fit w-fit rounded-2xl bg-primary-100 px-2 py-0.5 text-primary-600",
        isPaused && "bg-warning-complementary text-orange-80",
      )}
    >
      <p className={className}>{isPaused ? "Paused" : "Resumed"}</p>
    </div>
  );
};
