import { AnswersRenderer } from "~/common/components";
import { CheckInCodes } from "~/common/utils";
import { CheckInSubmittedFeedContextAnswersData } from "~/providers/utils/types/patientFeeds";

interface FeedCompletedCheckInAnswersProps {
  answers: CheckInSubmittedFeedContextAnswersData[];
  checkInCode: CheckInCodes;
}
export const FeedCompletedCheckinAnswers = ({
  answers,
  checkInCode,
}: FeedCompletedCheckInAnswersProps) => (
  <div className="flex w-full items-end justify-between pt-4">
    <AnswersRenderer
      answers={answers}
      checkinCode={checkInCode}
      className="w-2/3"
      isPreview
      containerClassName="bg-primary-100"
    />
  </div>
);
