import { INTERACTION_CREATION_TYPES } from "~/common/utils/types/index";
import { ActivityFeedOrder } from "~/providers/utils/types/interactions";
import { ActivityFeedBundleCheckinsData } from "./ActivityFeedBundleCheckinsData";
import { ActivityFeedScheduleCheckinData } from "./ActivityFeedScheduleCheckinData";
import { ActivityFeedSpecialRequestCheckinData } from "./ActivityFeedSpecialRequestCheckinData";

interface ActivityFeedOrderCheckinsDetailsProps {
  activityFeedOrder: ActivityFeedOrder;
}
export const ActivityFeedOrderCheckinsDetails = ({
  activityFeedOrder,
}: ActivityFeedOrderCheckinsDetailsProps) => {
  const lookup = {
    [INTERACTION_CREATION_TYPES.special_request]: (
      <ActivityFeedSpecialRequestCheckinData
        activityFeedOrder={activityFeedOrder}
      />
    ),
    [INTERACTION_CREATION_TYPES.schedule]: (
      <ActivityFeedScheduleCheckinData activityFeedOrder={activityFeedOrder} />
    ),
    [INTERACTION_CREATION_TYPES.bundle]: (
      <ActivityFeedBundleCheckinsData activityFeedOrder={activityFeedOrder} />
    ),
    [INTERACTION_CREATION_TYPES.patient_created]: null,
  };

  return lookup[activityFeedOrder.type];
};
