import { replaceUnderscores } from "~/common/utils";
import { BasicPatientInformation } from "~/providers/utils/types/patientConsultationReportTypes";

interface ContentProps {
  children: string;
}

const Title = ({ children }: ContentProps) => {
  return <p className="patient-information-title text-gray-10">{children}</p>;
};

const SectionContent = ({ children }: ContentProps) => {
  return (
    <p className="section-content text-base font-normal text-gray-70 first-letter:uppercase">
      {children}
    </p>
  );
};
interface BasicPatientInformationReportProps {
  data: BasicPatientInformation;
}

export const BasicPatientInformationReport = ({
  data,
}: BasicPatientInformationReportProps) => {
  const { date_of_birth, occupation, marital_status, gender } = data;
  return (
    <div className="patient-information-section grid grid-cols-4 gap-y-1">
      <Title>Date of birth</Title>
      <Title>Occupation</Title>
      <Title>Marital Status</Title>
      <Title>Gender</Title>
      <SectionContent>{date_of_birth}</SectionContent>
      <SectionContent>{occupation}</SectionContent>
      <SectionContent>{marital_status}</SectionContent>
      <SectionContent>{replaceUnderscores(gender)}</SectionContent>
    </div>
  );
};
