import { MedicationBasicData, PatientMedicationDetail } from "~/common/types";
import { formatMedicationName } from "~/common/utils";

export const generateMedicationCardTitle = (
  medicationData: MedicationBasicData,
  {
    medication_strength: { amount, unit },
    take,
    frequency: { description },
    as_needed,
  }: PatientMedicationDetail,
) => {
  return (
    <span className="capitalize">
      {formatMedicationName(medicationData)} - {amount}
      {unit}, {take} {description}
      {as_needed && ", as needed"}
    </span>
  );
};
