import { FieldErrors, UseFormRegisterReturn } from "react-hook-form";

import Input from "~/common/components/ui/Input";
import {
  ConsultationReportSchemaType,
  MentalStatusExaminationReportSchemaType,
} from "~/providers/utils";

interface MentalStatusReportProps {
  disableFields: boolean;
  register: (
    data: keyof MentalStatusExaminationReportSchemaType,
  ) => UseFormRegisterReturn<string>;
  errors: FieldErrors<ConsultationReportSchemaType>;
}

export const MentalStatusReport = ({
  disableFields,
  register,
  errors,
}: MentalStatusReportProps) => {
  const mentalStatusErrors = errors?.data?.mental_status_examination;
  return (
    <div className="flex w-full flex-col gap-3">
      <Input
        disabled={disableFields}
        label="Appearance"
        placeholder=""
        error={mentalStatusErrors?.appearance?.message}
        {...register("appearance")}
      />
      <Input
        disabled={disableFields}
        label="Behavior"
        placeholder=""
        error={mentalStatusErrors?.behavior?.message}
        {...register("behavior")}
      />
      <Input
        disabled={disableFields}
        label="Speech"
        placeholder=""
        error={mentalStatusErrors?.speech?.message}
        {...register("speech")}
      />
      <Input
        disabled={disableFields}
        label="Mood"
        placeholder=""
        error={mentalStatusErrors?.mood?.message}
        {...register("mood")}
      />
      <Input
        disabled={disableFields}
        label="Affect"
        placeholder=""
        error={mentalStatusErrors?.affect?.message}
        {...register("affect")}
      />
      <Input
        disabled={disableFields}
        label="Thought process"
        placeholder=""
        error={mentalStatusErrors?.thought_process?.message}
        {...register("thought_process")}
      />
      <Input
        disabled={disableFields}
        label="Thought content"
        placeholder=""
        error={mentalStatusErrors?.thought_content?.message}
        {...register("thought_content")}
      />
      <Input
        disabled={disableFields}
        label="Attention and Concentration"
        placeholder=""
        error={mentalStatusErrors?.attention_and_concentration?.message}
        {...register("attention_and_concentration")}
      />
      <Input
        disabled={disableFields}
        label="Judgement and Insight"
        placeholder=""
        error={
          errors?.data?.mental_status_examination?.judgment_and_insight?.message
        }
        {...register("judgment_and_insight")}
      />
    </div>
  );
};
