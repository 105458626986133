import { Patient, replaceUnderscores } from "~/common/utils";
import { ConsultationsRequestsRowProps } from "~/providers/components/consultationRequests/ConsultationsRequestsRow";

export const formatConsultationRequestRowData = (
  patient: Patient,
): ConsultationsRequestsRowProps => {
  const { id, name, last_name, last_feed, is_reviewed, status } = {
    ...patient,
  };

  return {
    id,
    patientName: `${name} ${last_name}`,
    description: replaceUnderscores(status),
    unreviewed: !is_reviewed,
    date: last_feed?.created_at,
  };
};
