import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { SectionHeader } from "~/common/components";
import { MultiSelectGroup } from "~/common/components/ui/multiSelectGroup";
import { scriptStatusGroup } from "~/common/constants";
import { useHandleMultipleFilters } from "~/common/hooks";
import { Group } from "~/common/utils";
import { SCRIPT_FILTERS } from "~/providers/constants";
import { PATIENT_FILTER_PARAMS } from "~/providers/constants/patients";
import SearchBar from "../SearchBar";

interface PrescriptionRequestFiltersProps {
  isLoading: boolean;
  disableFilters: boolean;
  ownersOptions: Group[];
}

export const PrescriptionRequestFilters = ({
  isLoading,
  disableFilters,
  ownersOptions,
}: PrescriptionRequestFiltersProps) => {
  const [params, setParams] = useSearchParams();
  const paramName = params.get(SCRIPT_FILTERS.patient_name) ?? undefined;
  const [patientFilter, setPatientFilter] = useState(paramName);

  useEffect(() => {
    if (!patientFilter) {
      params.delete(SCRIPT_FILTERS.patient_name);
      setParams(params);
    } else {
      params.delete(PATIENT_FILTER_PARAMS.page);
      const newParams = new URLSearchParams(params);
      newParams.set(SCRIPT_FILTERS.patient_name, patientFilter);
      setParams(newParams);
    }
  }, [patientFilter, params, setParams]);

  const { handleMultipleFilters } = useHandleMultipleFilters();

  const handleOwnerFilter = (filters: string[]) => {
    const filterByUnassigned = filters.includes(SCRIPT_FILTERS.unassigned);
    handleMultipleFilters(SCRIPT_FILTERS.unassigned, [`${filterByUnassigned}`]);
    const ownersId = filters.filter(
      (value) => value !== SCRIPT_FILTERS.unassigned,
    );
    handleMultipleFilters(SCRIPT_FILTERS.owner_id, ownersId);
  };

  const handleStatusFilter = (filters: string[]) => {
    handleMultipleFilters(SCRIPT_FILTERS.status, filters);
  };

  const filterByOwner =
    params.get(SCRIPT_FILTERS.unassigned) === "true"
      ? [SCRIPT_FILTERS.unassigned, ...params.getAll(SCRIPT_FILTERS.owner_id)]
      : params.getAll(SCRIPT_FILTERS.owner_id);

  return (
    <div className="flex items-center justify-between gap-4 xl:grid xl:grid-cols-3 xl:gap-0">
      <SectionHeader className="flex items-center text-xl font-semibold xl:text-3xl">
        Prescription requests
      </SectionHeader>
      <SearchBar
        className="w-full xl:w-3/4"
        disabled={disableFilters}
        placeholder="Search by patient name"
        isLoading={isLoading}
        value={patientFilter}
        setValue={setPatientFilter}
      />
      <div className="flex justify-end gap-4">
        <MultiSelectGroup
          name="owner"
          hasSelectAll={false}
          placeholder="Owner"
          disabled={disableFilters}
          selectNoneLabel="Clear all"
          options={ownersOptions}
          value={filterByOwner}
          onChange={handleOwnerFilter}
        />

        <MultiSelectGroup
          name="status"
          placeholder="Status"
          hasSelectAll={false}
          selectNoneLabel="Clear all"
          options={scriptStatusGroup}
          value={params.getAll(SCRIPT_FILTERS.status)}
          onChange={handleStatusFilter}
          disabled={disableFilters}
        />
      </div>
    </div>
  );
};
