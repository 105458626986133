import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { completeConsultationReport } from "~/providers/api/consultationReport";
import { PatientConsultationReportData } from "~/providers/utils/types/patientConsultationReportTypes";

export const useCompleteConsultationReport = (patientId: string) => {
  const queryClient = useQueryClient();
  const { mutate: completeConsultationReportMutation, isPending } = useMutation(
    {
      mutationFn: (consultationReportData: PatientConsultationReportData) =>
        completeConsultationReport(patientId, consultationReportData),
      onError: () =>
        toast.error(
          "We shoot trouble completing the consultation report, if the issue persists please reload the page!",
        ),
      onSuccess: async () => {
        toast.success("Consultation report completed successfully!");
        await queryClient.invalidateQueries({
          queryKey: ["getConsultationReport", patientId],
        });
      },
    },
  );

  return { completeConsultationReportMutation, isPending };
};
