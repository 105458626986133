import { twMerge } from "tailwind-merge";

import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";

interface ConsultationReportConfirmationModalProps {
  isPending: boolean;
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const ConsultationReportConfirmationModal = ({
  isPending,
  show,
  onClose,
  onSubmit,
}: ConsultationReportConfirmationModalProps) => (
  <Modal
    show={show}
    title="Complete consultation report"
    description="Are you sure you want to complete the consultation report? Once completed, you won’t be able to make further changes."
    onClose={onClose}
  >
    <CancelSubmitButtons
      cancelClassName="w-fit"
      submitClassName={twMerge("w-fit", isPending && "w-60 justify-center")}
      containerClassName="w-fit self-end"
      submitButtonLabel="Complete report"
      submitButtonType="button"
      handleCancel={onClose}
      isSubmitting={isPending}
      handleSubmit={onSubmit}
    />
  </Modal>
);
