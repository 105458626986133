import {
  BasicCheckIn,
  CheckInType,
  CheckInWithAnswers,
} from "~/common/utils/types/checkInTypes";
import { api } from "./axios";

export const getPatientCheckIn = async (
  patientId: string,
  patientCheckinId: string,
) => {
  const { data } = await api.get<CheckInWithAnswers>(
    `/v1/team-members/patients/${patientId}/checkins/${patientCheckinId}`,
  );
  return data;
};

export const getCheckInsBasicList = async (
  checkInType: CheckInType,
  exclude?: string,
  patientId?: string,
) => {
  const { data } = await api.get<BasicCheckIn[]>(`/v1/team-members/checkins`, {
    params: {
      checkin_type: checkInType,
      exclude: exclude,
      patient_id: patientId,
    },
  });
  return data;
};
