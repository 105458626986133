import { PatientMedicationScriptsStatus } from "~/common/types";
import { MedicationStatusBadge } from "../MedicationStatusBadge";

interface ScriptStatusCardProps {
  status: PatientMedicationScriptsStatus;
}

export const ScriptStatusCard = ({ status }: ScriptStatusCardProps) => {
  return (
    <div className="flex w-full flex-col gap-2 rounded-xl bg-neutral-300 p-2.5">
      <MedicationStatusBadge
        className="text-sm font-medium"
        isPaused={status?.is_paused}
      />
      <p className="break-all text-sm font-medium italic text-gray-40">
        {status?.note.content}
      </p>
    </div>
  );
};
