import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { AnswersRenderer } from "~/common/components/answers";
import { DateSquaredLayout } from "~/common/components/layouts/DateSquaredLayout";
import { Drawer } from "~/common/components/ui/Drawer";
import { NoteData } from "~/common/components/ui/NoteData";
import { checkInUtilities } from "~/common/utils/checkIn/checkInUtilities.ts";
import { useGetPatientCheckin } from "~/providers/hooks";
import { PatientParams } from "~/providers/utils";
import { SelectedPatientCheckin } from "../patientProfile";

interface PatientCheckinDrawerProps {
  handleClose: () => void;
  patientCheckIn?: SelectedPatientCheckin;
}
export const PatientCheckinDrawer = ({
  handleClose,
  patientCheckIn,
}: PatientCheckinDrawerProps) => {
  const { patientId } = useParams<keyof PatientParams>() as PatientParams;

  const { data: patientCheckinData } = useGetPatientCheckin({
    patientId,
    patientCheckInId: patientCheckIn?.feed.related_model_id,
    enabled: !patientCheckIn?.isProviderOrdered,
  });

  const { formatCheckInCode } = checkInUtilities();
  const hasNote =
    !!patientCheckinData?.note || !!patientCheckinData?.internal_note;

  return (
    <Drawer isOpen={Boolean(patientCheckinData)} onClose={handleClose}>
      <div className="flex w-full flex-col gap-3 overflow-auto">
        <div className="inset-0 flex w-full justify-between">
          <div className="flex w-full flex-col items-start justify-center">
            <h2 className="text-xl font-semibold leading-6">
              {formatCheckInCode(patientCheckinData?.checkin.code)}
            </h2>
          </div>
          {patientCheckinData?.due_date && (
            <DateSquaredLayout date={patientCheckinData.due_date} />
          )}
        </div>
        <div
          className={twMerge("flex flex-col gap-4", hasNote && "divide-y-2")}
        >
          <div className="flex flex-col gap-4">
            {patientCheckinData?.note && (
              <NoteData
                seeMoreButton
                title="Message for patient"
                note={patientCheckinData.note}
              />
            )}
            {patientCheckinData?.internal_note && (
              <NoteData
                seeMoreButton
                title="Internal note"
                note={patientCheckinData.internal_note}
              />
            )}
          </div>
          <AnswersRenderer
            answerClassName="p-2"
            className={twMerge(hasNote && "pt-6")}
            answers={patientCheckinData?.answers}
            questions={patientCheckinData?.checkin.questions}
          />
        </div>
      </div>
    </Drawer>
  );
};
