export const RELATED_MODEL = {
  patient_medication: "patient_medication",
  patient_checkin: "patient_checkin",
  script_request: "script_request",
  patient_bundle: "patient_bundle",
  patient: "patient",
  patient_checkin_schedule: "patient_checkin_schedule",
  note: "note",
} as const;

export type RelatedModelType =
  (typeof RELATED_MODEL)[keyof typeof RELATED_MODEL];

export const ACTIVITY_FEED_CODES = {
  consultation_requested: "consultation_requested",
  patient_archived: "patient_archived",
  referred_after_screening: "referred_after_screening",
  consultation_completed: "consultation_completed",
  screening_started: "screening_started",
  screening_submitted: "screening_submitted",
  screening_completed: "screening_completed",
  intake_started: "intake_started",
  intake_submitted: "intake_submitted",
  appointment_scheduled: "appointment_scheduled",
  bundle_ordered: "bundle_ordered",
  checkin_ordered: "checkin_ordered",
  checkin_submitted: "checkin_submitted",
  checkin_missed: "checkin_missed",
  checkin_deleted: "checkin_deleted",
  medication_added: "medication_added",
  medication_modified: "medication_modified",
  medication_discontinued: "medication_discontinued",
  prescription_paused: "prescription_paused",
  prescription_resumed: "prescription_resumed",
  note_added: "note_added",
  prescription_requested: "prescription_requested",
  prescription_cancelled: "prescription_cancelled",
  prescription_sent_to_pharmacy: "prescription_sent_to_pharmacy",
  prescription_issue_reported: "prescription_issue_reported",
  ongoing_care: "ongoing_care",
  patient_created: "patient_created",
  recurrent_checkin_ordered: "recurrent_checkin_ordered",
} as const;

export type ActivityFeedCode =
  (typeof ACTIVITY_FEED_CODES)[keyof typeof ACTIVITY_FEED_CODES];
