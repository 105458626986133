import { PropsWithChildren } from "react";

import { SectionHeader } from "~/common/components";

interface ConsultationInputLayoutProps extends PropsWithChildren {
  label: string;
  subtitle?: string;
}

export const ConsultationInputLayout = ({
  label,
  subtitle,
  children,
}: ConsultationInputLayoutProps) => (
  <div className="flex w-full flex-col gap-4 rounded-md bg-primary-50 p-5">
    <SectionHeader className="font-sans text-xl">
      {label}
      <span className="pl-2 text-base font-light text-gray-35">{subtitle}</span>
    </SectionHeader>
    {children}
  </div>
);
