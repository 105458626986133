import { useQuery } from "@tanstack/react-query";

import { getConsultationCompletionDetail } from "~/providers/api/consultationDetails";

export const useGetConsultationCompletionDetails = (
  patientId?: string,
  enabled = true,
) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getConsultationCompletionDetail(patientId ?? ""),
    queryKey: ["getConsultationCompletionDetail", patientId],
    enabled: !!patientId && enabled,
  });
  return { data, isLoading };
};
