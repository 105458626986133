import { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  IntakeCode,
  intakeSectionTitle,
} from "~/common/utils/consultations/intake";
import {
  ScreeningCode,
  screeningSectionTitle,
} from "~/common/utils/consultations/screening";
import { useRawResponsesStore } from "~/providers/stores";
import { PatientData, PatientParams } from "~/providers/utils";
import { isIntakeResponses } from "~/providers/utils/consultationRequests/rawResponses";
import {
  PatientRawResponsesIntake,
  PatientRawResponsesScreening,
} from "~/providers/utils/types/rawResponsesTypes";
import { ExpandCollapseButtons } from "./ExpandCollapseButtons";
import { RawResponseSectionCard } from "./RawResponseSectionCard";
import { RawResponsesRender } from "./RawResponsesRender";

interface RawResponsesSectionProps {
  rawResponses: PatientRawResponsesIntake | PatientRawResponsesScreening;
  patient?: PatientData;
}

const RawResponsesSection = ({
  rawResponses: rawResponses,
  patient,
}: RawResponsesSectionProps) => {
  const isIntakeResponse = isIntakeResponses(rawResponses);
  const sections = isIntakeResponse
    ? rawResponses.intakes
    : rawResponses.screenings;
  const { patientId: currentPatientId } = useParams<
    keyof PatientParams
  >() as PatientParams;

  const {
    expandedSections,
    toggleExpandAll,
    toggleSection,
    setPatientId,
    patientId,
  } = useRawResponsesStore();

  useEffect(() => {
    const expandDefaultSections = !patientId || patientId != currentPatientId;
    if (expandDefaultSections) {
      setPatientId(currentPatientId);
      toggleExpandAll(sections, true);
    }
  }, [sections, toggleExpandAll, patientId, currentPatientId, setPatientId]);

  const expandAll = (expand: boolean) => {
    toggleExpandAll(sections, expand);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <h2 className="font-outfit text-2xl font-medium text-gray-800">
          {isIntakeResponse ? "Intake" : "Screening"}
        </h2>
        <ExpandCollapseButtons
          isExpandedAll={sections.every(({ code }) => expandedSections[code])}
          expandAll={expandAll}
        />
      </div>
      {sections.map(({ code, order, answers }) => (
        <RawResponseSectionCard
          key={code}
          isExpanded={expandedSections[code] ?? false}
          toggleSection={() => toggleSection(code)}
          order={order}
          title={
            isIntakeResponse
              ? intakeSectionTitle[code as IntakeCode]
              : screeningSectionTitle[code as ScreeningCode]
          }
        >
          {answers?.map((answer, index) => (
            <RawResponsesRender
              answer={answer}
              isIntake={isIntakeResponse}
              pronouns={patient?.pronouns}
              key={`${code} answer ${index}`}
            />
          ))}
        </RawResponseSectionCard>
      ))}
    </div>
  );
};

export default RawResponsesSection;
