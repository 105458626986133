import {
  INTERACTION_CREATION_TYPES,
  PATIENT_INTERACTION_TYPES,
} from "~/common/utils";
import { SelectedPatientCheckin } from "~/providers/components";
import { ActivityFeedOrder } from "~/providers/utils/types/interactions";
import {
  BundleFeed,
  CheckInOrderedFeed,
  RecurrentCheckInOrderedFeed,
  SELECTED_PATIENT_CHECKIN_TYPE,
} from "../types/patientFeeds";

export const generatePatientCheckinOrder = (
  selectedPatientCheckin: SelectedPatientCheckin,
): ActivityFeedOrder | undefined => {
  const { isProviderOrdered, type, feed } = selectedPatientCheckin;

  if (isProviderOrdered && type === SELECTED_PATIENT_CHECKIN_TYPE.regular) {
    const { data } = (feed as CheckInOrderedFeed).context;
    return {
      ...data,
      checkin: undefined,
      checkins: [data.checkin],
      type: data.creation_type,
    };
  }

  if (type === SELECTED_PATIENT_CHECKIN_TYPE.scheduled) {
    const { data } = (feed as RecurrentCheckInOrderedFeed).context;
    return {
      ...data,
      checkin: undefined,
      type: INTERACTION_CREATION_TYPES.schedule,
      description: data.checkin.code,
      due_date: data?.next_interaction_due_date,
      checkins: [data.checkin],
    };
  }

  if (type === SELECTED_PATIENT_CHECKIN_TYPE.bundle) {
    const { bundle } = (feed as BundleFeed).context;
    const lastPatientCheckin =
      bundle.patient_checkins[bundle.patient_checkins.length - 1];
    return {
      ...lastPatientCheckin,
      checkins: bundle.patient_checkins,
      type: PATIENT_INTERACTION_TYPES.bundle,
      note: lastPatientCheckin.note,
      internal_note: lastPatientCheckin.note,
    };
  }
};
