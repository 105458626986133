import { PATIENT_STATUS } from "~/common/constants";
import { PatientStatus } from "~/common/utils";
import { ROUTES } from "../router";

export const getPatientBaseUrl = (patientId: string) =>
  `/v1/team-members/patients/${patientId}`;

export const getPatientCompleteAndSignRoute = (patientStatus: PatientStatus) =>
  patientStatus === PATIENT_STATUS.ongoing_care
    ? ROUTES.patientComplete
    : ROUTES.consultationComplete;
