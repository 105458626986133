import { getIntervalString } from "~/common/utils/checkIn/getOrderRepetition";
import { formatDate } from "~/providers/utils/orders/getDateText";
import { ActivityFeedOrder } from "~/providers/utils/types/interactions";

interface ActivityFeedScheduleCheckinDataProps {
  activityFeedOrder: ActivityFeedOrder;
}
export const ActivityFeedScheduleCheckinData = ({
  activityFeedOrder,
}: ActivityFeedScheduleCheckinDataProps) => {
  const { frequency, interval, due_date, end_date } = activityFeedOrder;

  return (
    <div className="flex flex-col gap-4 pt-6">
      <div className="flex flex-row gap-2">
        <p className="font-light text-gray-10">Repeat this order</p>
        {frequency && interval && (
          <p>{getIntervalString(frequency, interval)}</p>
        )}
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-2">
          <p className="font-light text-gray-10">Next check-in due</p>
          <p>{formatDate(due_date)}</p>
        </div>
        {end_date && (
          <div className="flex flex-row gap-2">
            <p className="font-light text-gray-10">Repeats until</p>
            <p>{formatDate(end_date)}</p>
          </div>
        )}
      </div>
    </div>
  );
};
