import { Link } from "react-router-dom";

import { ChevronIcon } from "~/common/components/icons";
import { DIRECTIONS } from "~/common/constants";
import { CONSULTATION_PATH, ROUTES } from "~/providers/router";

export const PatientHeader = ({ name }: { name: string }) => {
  const actualPath = window.location.pathname;
  const actualPathIncludesConsultation = actualPath.includes(CONSULTATION_PATH);
  return (
    <div className="flex w-full flex-row items-center gap-3">
      <Link
        className="text-gray-10"
        to={
          actualPathIncludesConsultation
            ? ROUTES.consultationRequests
            : ROUTES.home
        }
      >
        {actualPathIncludesConsultation ? "Consultations" : "Patients"}
      </Link>

      <ChevronIcon
        className="size-2.5 text-gray-10"
        direction={DIRECTIONS.right}
      />
      <p className="text-primary-600">{name}</p>
    </div>
  );
};
