import { SectionHeader, Typography } from "~/common/components";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";

interface StaffConsultationInProgress {
  providerReference: string;
}
export const StaffConsultationInProgress = ({
  providerReference,
}: StaffConsultationInProgress) => {
  return (
    <div className="flex size-full flex-col items-center justify-center gap-3">
      <SectionHeader>Consultation in progress</SectionHeader>
      <Typography
        variant={TYPOGRAPHY_VARIANTS.secondary}
        className="text-center text-base lg:w-1/4"
      >
        {`This report will be available once ${providerReference} completes the consultation.`}
      </Typography>
    </div>
  );
};
