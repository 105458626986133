import { FieldErrors, UseFormRegister, UseFormWatch } from "react-hook-form";

import DatePicker from "~/common/components/ui/DatePicker";
import TextArea from "~/common/components/ui/Textarea";
import { getTodayDate } from "~/common/utils/getTodayDate";
import { TEXT_MAX_LENGTH } from "~/providers/constants";
import { UpsertMedicationFormValues } from "../UpsertMedicationModal";

interface AdditionalInformationInputsProps {
  register: (
    field: keyof UpsertMedicationFormValues["patient_medications_detail"],
  ) => ReturnType<UseFormRegister<UpsertMedicationFormValues>>;
  errors: FieldErrors<UpsertMedicationFormValues>;
  watch: UseFormWatch<UpsertMedicationFormValues>;
}
export const AdditionalInformationInputs = ({
  register,
  errors,
  watch,
}: AdditionalInformationInputsProps) => {
  const today = getTodayDate();

  return (
    <>
      <TextArea
        label="Internal note"
        {...register("internal_note")}
        error={errors.patient_medications_detail?.internal_note?.message}
        id="patient_medications_detail.internal_note"
        maxCharacters={TEXT_MAX_LENGTH}
        charactersCount={
          watch("patient_medications_detail.internal_note")?.length
        }
      />
      <TextArea
        label="Message to patient"
        {...register("message_to_patient")}
        error={errors.patient_medications_detail?.message_to_patient?.message}
        id="patient_medications_detail.message_to_patient"
        charactersCount={
          watch("patient_medications_detail.message_to_patient")?.length
        }
        maxCharacters={TEXT_MAX_LENGTH}
      />
      <DatePicker
        label="Start date"
        error={errors.patient_medications_detail?.start_date?.message}
        {...register("start_date")}
        min={today}
      />
    </>
  );
};
