import { generatePath, matchPath, NavigateFunction } from "react-router-dom";

import { PATIENT_STATUS } from "~/common/constants";
import { PatientStatus } from "~/common/utils";
import {
  BASE_LEAD_PATIENT_PROFILE_PATH,
  BASE_PATIENT_PROFILE_PATH,
  CONSULTATION_PATH,
  ROUTES,
} from "~/providers/router";

export interface MenuOptionData {
  label: string;
  onClick: () => void;
  value?: string;
  notificationAmount?: number;
  current?: boolean;
  enabled?: boolean;
}

export const getPatientProfileMenuOptions = (
  navigate: NavigateFunction,
  patientId: string,
  patientStatus: PatientStatus,
  has_consultation = false,
): MenuOptionData[] => {
  const actualPath = window.location.pathname;
  const isPatientActive = patientStatus === PATIENT_STATUS.ongoing_care;

  const patientInfoRoute = isPatientActive
    ? ROUTES.patientInfo
    : ROUTES.patientConsultationInfo;

  const consultationRoute = isPatientActive
    ? ROUTES.patientOverview
    : ROUTES.consultationOverview;

  const activityFeedRoute =
    patientStatus !== PATIENT_STATUS.ongoing_care
      ? ROUTES.leadPatientActivityFeed
      : ROUTES.activePatientActivityFeed;

  const baseConsultationPath = isPatientActive
    ? BASE_PATIENT_PROFILE_PATH
    : BASE_LEAD_PATIENT_PROFILE_PATH;

  return [
    {
      label: "Activity feed",
      current: !!matchPath(activityFeedRoute, actualPath),
      onClick: () =>
        navigate(
          generatePath(activityFeedRoute, {
            patientId: patientId,
          }),
        ),
      enabled: true,
    },
    {
      label: "Medications",
      current: !!matchPath(ROUTES.medication, actualPath),
      onClick: () =>
        navigate(
          generatePath(ROUTES.medication, {
            patientId: patientId,
          }),
        ),
      enabled: isPatientActive,
    },
    {
      label: "Orders",
      current: !!matchPath(ROUTES.orders, actualPath),
      onClick: () =>
        navigate(
          generatePath(ROUTES.orders, {
            patientId: patientId,
          }),
        ),
      enabled: isPatientActive,
    },
    {
      label: "Consultation",
      current: !!matchPath(
        `${baseConsultationPath}${CONSULTATION_PATH}/*`,
        actualPath,
      ),
      onClick: () => navigate(generatePath(consultationRoute, { patientId })),
      enabled: has_consultation,
    },
    {
      label: "Patient info",
      current: !!matchPath(patientInfoRoute, actualPath),
      onClick: () =>
        navigate(
          generatePath(patientInfoRoute, {
            patientId: patientId,
          }),
        ),
      enabled: true,
    },
  ];
};
