import { useEffect } from "react";

import { SectionHeader, TopBar } from "~/common/components";
import { MultiSelectGroup } from "~/common/components/ui/multiSelectGroup";
import {
  allFilters,
  patientSearchFilters,
} from "~/providers/constants/patientSearchConstants";
import { useHandlePatientSearchFilters } from "~/providers/hooks/useHandlePatientSearchFilters";
import { useUiStore } from "~/providers/stores";
import SearchBar from "../SearchBar";

interface PatientSearchFiltersProps {
  isLoading?: boolean;
  disableFilters?: boolean;
  totalPatients?: number;
}

export const PatientSearchFilters = ({
  isLoading,
  disableFilters,
  totalPatients = 0,
}: PatientSearchFiltersProps) => {
  const { setSideBarOpen } = useUiStore();

  const {
    handleNameFilterChange,
    handlePatientSearchFilters,
    multiSelectGroupValues,
    nameFilter,
    currentFilters,
  } = useHandlePatientSearchFilters();

  useEffect(() => {
    !currentFilters.length && handlePatientSearchFilters(allFilters);
  }, [currentFilters, handlePatientSearchFilters]);

  return (
    <div className="grid grid-cols-3">
      <div className="flex items-center gap-4">
        <TopBar handleOpenSideBar={() => setSideBarOpen(true)} />
        <SectionHeader className="flex items-center text-xl font-semibold xl:text-3xl">{`Patients (${totalPatients})`}</SectionHeader>
      </div>
      <SearchBar
        className="w-full"
        disabled={disableFilters}
        isLoading={isLoading}
        value={nameFilter}
        placeholder="Search by patient name"
        setValue={handleNameFilterChange}
      />
      <div className="flex items-center justify-end">
        <MultiSelectGroup
          onChange={handlePatientSearchFilters}
          disabled={disableFilters}
          value={multiSelectGroupValues}
          placeholder="Filter by"
          showCounter={false}
          name="patientSearch"
          options={patientSearchFilters}
          containerClassName="min-w-60"
        />
      </div>
    </div>
  );
};
