import type { Location } from "react-router-dom";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { LoadingState } from "~/common/components";
import {
  ConsultationLayout,
  Layout,
  PatientProfileLayout,
} from "~/providers/layout";
import {
  ForgotPassword,
  Home,
  InternalErrorScreen,
  LeadPatientActivityFeed,
  LeadPatientCompleteAndSign,
  LeadPatientConsultationReport,
  LeadPatientInformation,
  LeadPatientOverview,
  LeadPatientRawResponses,
  Login,
  NotFoundScreen,
  OngoingPatientCompleteAndSign,
  OngoingPatientConsultationReport,
  OngoingPatientInformation,
  OngoingPatientOverview,
  OngoingPatientRawResponses,
  PatientActivityFeed,
  PatientMedication,
  PatientOrders,
  ResetPassword,
} from "~/providers/screens";
import { PrescriptionRequest } from "../components";
import { PaymentAndCarePlans } from "../components/providerSettings/paymentAndCarePlans";
import { ProviderDetails } from "../components/providerSettings/ProviderDetails";
import { useTeamMemberIsAuth } from "../hooks";
import { SettingsLayout } from "../layout/SettingsLayout";
import { ConsultationRequestsScreen } from "../screens/ConsultationRequestsScreen";
import { ExportConsultationReportScreen } from "../screens/ExportConsultationReportScreen";
import { PatientSearch } from "../screens/PatientSearch";
import { ROUTES } from "./routes";

export const Router = () => {
  const location = useLocation();
  const { previousLocation } = (location.state ?? {}) as {
    previousLocation?: Location;
  };

  const { data: isLoggedIn, isFetching } = useTeamMemberIsAuth();

  if (isFetching) return <LoadingState className="h-screen-calculated" />;

  return (
    <Routes location={previousLocation ?? location}>
      {/* PUBLIC ONLY ROUTES */}
      {!isLoggedIn && (
        <>
          <Route element={<Navigate to={ROUTES.login} replace />} path={"*"} />
          <Route element={<Login />} path={ROUTES.login} />
          <Route element={<ForgotPassword />} path={ROUTES.forgotPassword} />
          <Route element={<ResetPassword />} path={ROUTES.resetPassword} />
        </>
      )}
      {/* PRIVATE ONLY ROUTES */}
      {isLoggedIn && (
        <>
          <Route element={<Navigate to={ROUTES.home} />} path={"*"} />
          <Route path={ROUTES.notFound || "*"} element={<NotFoundScreen />} />
          <Route
            path={ROUTES.internalError}
            element={<InternalErrorScreen />}
          />
          <Route element={<Layout />}>
            <Route element={<Home />} path={ROUTES.home} />
            <Route
              element={<ConsultationRequestsScreen />}
              path={ROUTES.consultationRequests}
            />
            <Route
              element={<PrescriptionRequest />}
              path={ROUTES.prescriptionRequest}
            />
            <Route element={<PatientProfileLayout />}>
              <Route element={<ConsultationLayout />}>
                <Route
                  path={ROUTES.consultationOverview}
                  element={<LeadPatientOverview />}
                />
                <Route
                  path={ROUTES.consultationResponses}
                  element={<LeadPatientRawResponses />}
                />
                <Route
                  path={ROUTES.consultationComplete}
                  element={<LeadPatientCompleteAndSign />}
                />
                <Route
                  path={ROUTES.leadPatientConsultationReport}
                  element={<LeadPatientConsultationReport />}
                />
                <Route
                  path={ROUTES.patientOverview}
                  element={<OngoingPatientOverview />}
                />
                <Route
                  path={ROUTES.patientResponses}
                  element={<OngoingPatientRawResponses />}
                />
                <Route
                  path={ROUTES.patientComplete}
                  element={<OngoingPatientCompleteAndSign />}
                />
                <Route
                  path={ROUTES.ongoingPatientConsultationReport}
                  element={<OngoingPatientConsultationReport />}
                />
              </Route>
              <Route
                element={<PatientActivityFeed />}
                path={ROUTES.activePatientActivityFeed}
              />
              <Route
                element={<LeadPatientActivityFeed />}
                path={ROUTES.leadPatientActivityFeed}
              />
              <Route element={<PatientMedication />} path={ROUTES.medication} />
              <Route
                element={<OngoingPatientInformation />}
                path={ROUTES.patientInfo}
              />
              <Route
                element={<LeadPatientInformation />}
                path={ROUTES.patientConsultationInfo}
              />
              <Route element={<PatientOrders />} path={ROUTES.orders} />
            </Route>
            <Route element={<SettingsLayout />}>
              <Route element={<ProviderDetails />} path={ROUTES.account} />
              <Route
                element={<PaymentAndCarePlans />}
                path={ROUTES.paymentAndCarePlans}
              />
            </Route>
            <Route element={<PatientSearch />} path={ROUTES.patientSearch} />
          </Route>
          <Route
            path={ROUTES.exportConsultationReport}
            element={<ExportConsultationReportScreen />}
          />
        </>
      )}
    </Routes>
  );
};
