import { useParams } from "react-router-dom";

import { Button, LoadingState, SectionHeader } from "~/common/components";
import { BUTTON_SIZES, BUTTON_VARIANTS } from "~/common/constants";
import { useGetConsultationReport, useGetPatient } from "~/providers/hooks";
import { isConsultationCompleted, PatientParams } from "~/providers/utils";
import { SubmittedConsultationReportDataRenderer } from "./SubmittedConsultationReportDataRenderer";

export const ExportConsultationReport = () => {
  const { patientId } = useParams<keyof PatientParams>() as PatientParams;
  const { data: patientData } = useGetPatient(patientId);

  const { data: completedReportData, isLoading } = useGetConsultationReport(
    patientId,
    isConsultationCompleted(patientData?.status),
  );

  if (!completedReportData) {
    return null;
  }

  if (isLoading) return <LoadingState className="h-screen-calculated" />;

  return (
    <div className="printable-container flex flex-col gap-4 px-6 py-6">
      <div className="flex w-full justify-between">
        <SectionHeader>Consultation report (H&P)</SectionHeader>
        <Button
          variant={BUTTON_VARIANTS.outline_black}
          size={BUTTON_SIZES.sm}
          className="w-fit print:hidden"
          onClick={() => {
            window.print();
          }}
        >
          Print report
        </Button>
      </div>
      <div
        id="printable-content"
        className="scrollable-content flex flex-col gap-3"
      >
        <SubmittedConsultationReportDataRenderer data={completedReportData} />
      </div>
    </div>
  );
};
