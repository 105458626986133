import { Drawer } from "~/common/components";
import { MergedMedicationRecord } from "~/common/types";
import { MedicationDetails } from "~/providers/components/patientProfile/medications/MedicationDetails";
import { ScriptStatusCard } from "./ScriptStatusCard";

interface MedicationDetailsDrawerProps {
  medicationName: string;
  handleClose: () => void;
  medication?: MergedMedicationRecord;
}

export const MedicationDetailsDrawer = ({
  medicationName,
  handleClose,
  medication,
}: MedicationDetailsDrawerProps) => {
  return (
    <Drawer
      isOpen={!!medication}
      onClose={handleClose}
      childrenContainerClassName="gap-0"
      header={
        medication?.status && <ScriptStatusCard status={medication.status} />
      }
    >
      <MedicationDetails
        medicationName={medicationName}
        medicationDetail={medication?.medication_detail}
      />
    </Drawer>
  );
};
