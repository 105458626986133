import { FieldErrors, UseFormRegisterReturn } from "react-hook-form";

import Input from "~/common/components/ui/Input";
import {
  BasicPatientInformationSchemaType,
  ConsultationReportSchemaType,
} from "~/providers/utils";

interface BasicPatientInformationInputsProps {
  register: (
    data: keyof BasicPatientInformationSchemaType,
  ) => UseFormRegisterReturn<string>;
  errors: FieldErrors<ConsultationReportSchemaType>;
}

export const BasicPatientInformationInputs = ({
  register,
  errors,
}: BasicPatientInformationInputsProps) => {
  const patientInformationErrors = errors?.data?.basic_patient_information;
  return (
    <div className="flex w-4/5 gap-4">
      <Input
        label="Date of birth"
        placeholder=""
        disabled
        error={patientInformationErrors?.date_of_birth?.message}
        containerClassName="w-3/5"
        {...register("date_of_birth")}
      />
      <Input
        label="Occupation"
        placeholder=""
        disabled
        error={patientInformationErrors?.occupation?.message}
        {...register("occupation")}
      />
      <Input
        label="Marital status"
        disabled
        placeholder=""
        error={patientInformationErrors?.marital_status?.message}
        {...register("marital_status")}
      />
      <Input
        label="Gender"
        disabled
        placeholder=""
        error={patientInformationErrors?.gender?.message}
        {...register("gender")}
      />
    </div>
  );
};
