import { PatientMedicationScriptsStatus } from "~/common/types";
import { Address } from "~/common/types/pharmacies";
import { Script } from "~/common/types/scriptsTypes";
import { PatientStatus, TeamMember } from "~/common/utils";
import { PatientMedicationScript, PronounsOptions } from "~/patients/utils";
import { NoteType } from "../constants";

export interface EmergencyContact {
  name: string;
  last_name?: string;
  relationship: string;
  email: string;
  phone_number: string;
}

export interface Note {
  content?: string;
  title: string;
  id: string;
}
export interface PatientData {
  email: string;
  birthday: string | null;
  name: string;
  last_name: string;
  id: string;
  pronouns: PronounsOptions;
  provider: TeamMember;
  phone_number: string;
  email_verified_at?: string;
  addresses: Address[];
  emergency_contacts: EmergencyContact[];
  id_photo_path?: string;
  note: Note | null;
  status: PatientStatus;
  has_consultation?: boolean;
}

export interface PatientNoteData {
  id: string;
  note?: Note;
  note_id: string;
  patient_id: string;
  type: NoteType;
}

export interface ProviderMedication extends PatientMedicationScript {
  scripts_statuses?: PatientMedicationScriptsStatus[];
}
export interface ProviderScriptDetails
  extends Omit<Script, "patient_medication"> {
  patient_medication: ProviderMedication;
}

export const PATIENTS_FILTERS = {
  all: "all",
  unreviewed: "unreviewed",
  reviewed: "reviewed",
} as const;

export const PATIENT_LIST_STATUS_FILTERS = {
  lead: "lead",
  ongoing_care: "ongoing_care",
  all: "all",
} as const;

export const DESCRIPTIVE_TAG_VARIANTS = {
  script_paused: "script_paused",
  simple: "simple",
} as const;

export type DescriptiveTagVariant =
  (typeof DESCRIPTIVE_TAG_VARIANTS)[keyof typeof DESCRIPTIVE_TAG_VARIANTS];

export type PatientsFilters = keyof typeof PATIENTS_FILTERS;

export interface PaymentSettings {
  provider_id: string;
  secret_key: string;
  publishable_key: string;
  default_consultation_fee: number;
}

export interface ConsultationDetails {
  id: string;
  provider_id: string;
  price: number;
  currency: string;
}

export interface PatientParams {
  patientId: string;
}
