import { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { Button, Typography } from "~/common/components";
import { BUTTON_VARIANTS } from "~/common/constants";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";
import { ACTIVITY_FEED_CODES } from "~/providers/constants";
import { useGetActivityFeedCardData } from "~/providers/hooks/patientInteractions/useGetActivityFeedCardData";
import { formatDate } from "~/providers/utils";
import { PatientFeed } from "~/providers/utils/types/interactions";
import {
  PatientMedicationDetailsDrawerProps,
  SelectedPatientCheckin,
} from "../ActivityFeed";
import { RenderFeedCardChildren } from "./RenderFeedCardChildren";

interface ActivityFeedCardProps
  extends ComponentPropsWithoutRef<"div">,
    PropsWithChildren {
  feedOption: PatientFeed;
  viewPatientCheckInDetails: (
    selectedPatientCheckinInfo: SelectedPatientCheckin,
  ) => void;
  viewMedicationDetails: (
    medication: PatientMedicationDetailsDrawerProps,
  ) => void;
}

export const ActivityFeedCard = ({
  feedOption,
  viewPatientCheckInDetails,
  viewMedicationDetails,
  ...props
}: ActivityFeedCardProps) => {
  const {
    code,
    generated_by_name: generatedBy,
    patient_generated: patientGenerated,
    created_at,
  } = feedOption;

  const formattedDate = formatDate(created_at);

  const { getFeedData } = useGetActivityFeedCardData({
    viewPatientCheckInDetails,
    viewMedicationDetails,
  });
  const feedData = getFeedData(feedOption);

  const { title, description, icon, internalNote, seeDetails } = {
    ...feedData,
  };

  const { seeDetailsDescription, handleSeeDetails } = { ...seeDetails };

  if (!title) return null;

  return (
    <div
      className={twMerge(
        "flex size-full flex-col gap-4 divide-y rounded-md border border-neutral-80 p-4 text-gray-70",
        patientGenerated &&
          "divide-primary-200 border-primary-200 bg-primary-50",
      )}
    >
      <div className={"flex size-full flex-row items-center gap-2"} {...props}>
        <div className="flex h-full items-start">{icon?.()}</div>
        <div className="flex w-full flex-col gap-1">
          <div className="flex w-full justify-between">
            {generatedBy && (
              <Typography className="font-medium">{generatedBy}</Typography>
            )}
            {seeDetails && (
              <Button
                variant={BUTTON_VARIANTS.outline_none}
                className="w-fit p-0 text-primary-600"
                onClick={handleSeeDetails}
              >
                {seeDetailsDescription}
              </Button>
            )}
          </div>
          <div className="flex w-full justify-between">
            <div className="flex items-center">
              <Typography
                className={twMerge(
                  "text-base",
                  (code === ACTIVITY_FEED_CODES.prescription_paused ||
                    code === ACTIVITY_FEED_CODES.checkin_missed) &&
                    "text-orange-70",
                )}
              >
                {title}
              </Typography>
              {description && (
                <>
                  <span className="px-1 font-light text-neutral-80">|</span>
                  <Typography className="text-base first-letter:uppercase">
                    {description}
                  </Typography>
                </>
              )}
            </div>
            <Typography
              variant={TYPOGRAPHY_VARIANTS.secondary}
              className="text-base font-light"
            >
              {formattedDate}
            </Typography>
          </div>
        </div>
      </div>
      <RenderFeedCardChildren
        feedOption={feedOption}
        internalNote={internalNote}
      />
    </div>
  );
};
