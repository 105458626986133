import { useState } from "react";

import { Button, Typography } from "~/common/components";
import { BUTTON_SIZES } from "~/common/constants";
import { useIsTeamMemberStaff } from "~/providers/hooks";
import { PatientCarePlansList } from "~/providers/utils/types/carePlanTypes";
import { CarePlansCard, ChangePlanModal } from "../carePlans";

interface CurrentCarePlanProps {
  carePlans?: PatientCarePlansList[];
}

export const CurrentCarePlan = ({ carePlans }: CurrentCarePlanProps) => {
  const [currentPlanModal, setCurrentPlanModal] = useState(false);
  const { isStaff } = useIsTeamMemberStaff();

  const toggleCurrentPlanModal = () => setCurrentPlanModal((prev) => !prev);

  if (!carePlans?.length) return null;

  const currentCarePlan = carePlans[0];

  return (
    <>
      <div className="flex flex-col gap-6 border-y py-6">
        <div className="flex justify-between">
          <Typography className="font-outfit text-2xl font-medium">
            Current plan
          </Typography>
          {!isStaff && (
            <Button
              size={BUTTON_SIZES.sm}
              className="w-fit"
              onClick={toggleCurrentPlanModal}
            >
              Change plan
            </Button>
          )}
        </div>
        <CarePlansCard
          carePlans={carePlans}
          currentCarePlan={currentCarePlan}
        />
      </div>

      {!isStaff && (
        <ChangePlanModal
          show={currentPlanModal}
          onClose={toggleCurrentPlanModal}
          dateStarted={currentCarePlan.start_date}
          plan={currentCarePlan.provider_care_plan.name}
          currentCarePlanId={currentCarePlan.provider_care_plan.id}
        />
      )}
    </>
  );
};
