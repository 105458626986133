import { useState } from "react";

import { Table } from "~/common/components/table";
import { TableHead } from "~/common/components/table/TableHead";
import { TableHeader } from "~/common/components/table/TableHeader";
import { MergedMedicationRecord } from "~/common/types";
import {
  formatDate,
  generateFrequency,
  generateStrength,
} from "~/providers/utils";
import { MedicationDetailsDrawer } from "./detailsDrawer";
import { MedicationsTableRow } from "./MedicationsTableRow";

interface MedicationsTableProps {
  medicationName: string;
  mergedMedicationRecords?: MergedMedicationRecord[];
}

export const MedicationsTable = ({
  medicationName,
  mergedMedicationRecords,
}: MedicationsTableProps) => {
  const [selectedMedication, setSelectedMedication] = useState<
    MergedMedicationRecord | undefined
  >();

  return (
    <div className="w-full overflow-auto rounded-xl border border-gray-300">
      <Table>
        <TableHead className="border-neutral-700 bg-neutral-25">
          <tr>
            <TableHeader>Date</TableHeader>
            <TableHeader />
            <TableHeader>Strength</TableHeader>
            <TableHeader>Take</TableHeader>
            <TableHeader>Frequency</TableHeader>
            <TableHeader />
          </tr>
        </TableHead>
        <tbody className="bg-white text-sm text-gray-70">
          {mergedMedicationRecords?.map((med) => (
            <MedicationsTableRow
              key={med.medication_detail.id}
              date={formatDate(med.medication_detail.start_date)}
              strength={generateStrength(
                med.medication_detail.medication_strength,
              )}
              take={med.medication_detail.take}
              frequency={generateFrequency(med.medication_detail)}
              handleSeeDetails={() => setSelectedMedication(med)}
              status={med.status}
            />
          ))}
        </tbody>
      </Table>
      <MedicationDetailsDrawer
        medication={selectedMedication}
        medicationName={medicationName}
        handleClose={() => setSelectedMedication(undefined)}
      />
    </div>
  );
};
