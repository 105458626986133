import { useState } from "react";
import { useNavigate, useOutlet, useParams } from "react-router-dom";

import { Button, NoteIcon } from "~/common/components";
import NavMenu from "~/common/components/ui/NavMenu";
import { BUTTON_SIZES, PATIENT_STATUS } from "~/common/constants";
import { useGetPatient } from "~/providers/hooks/queries/useGetPatient";
import { useUiStore } from "~/providers/stores/useUiStore";
import { getFullName } from "~/providers/utils/GetFullName";
import { NewNoteModal } from "../components";
import { PatientHeader } from "../components/patientProfile/PatientHeader";
import { useIsTeamMemberStaff } from "../hooks";
import { getPatientProfileMenuOptions } from "../utils/getPatientProfileMenuOptions";

export const PatientProfileLayout = () => {
  const outlet = useOutlet();
  const { setSideBarOpen } = useUiStore();
  const { isStaff } = useIsTeamMemberStaff();

  const navigate = useNavigate();
  const [newNoteModalOpen, setNewNoteModalOpen] = useState(false);
  const toggleNewNoteModal = () => setNewNoteModalOpen((prev) => !prev);

  const { patientId } = useParams();
  const { data: patient } = useGetPatient(patientId);

  if (!patient || !patientId) {
    return null;
  }

  const menuOptions = getPatientProfileMenuOptions(
    navigate,
    patient.id,
    patient.status,
    patient.has_consultation,
  );

  const actionButton =
    patient.status === PATIENT_STATUS.ongoing_care && !isStaff ? (
      <Button
        size={BUTTON_SIZES.sm}
        className="w-fit"
        variant="outline-black"
        onClick={toggleNewNoteModal}
        left={<NoteIcon className="text-gray-700" />}
      >
        New note
      </Button>
    ) : null;

  return (
    <>
      <div className="flex h-screen-calculated w-full flex-col text-sm">
        <NavMenu
          headerInfo={
            <PatientHeader
              name={getFullName(patient?.name, patient?.last_name)}
            />
          }
          title={getFullName(patient?.name, patient?.last_name)}
          menuOptions={menuOptions}
          handleOpenSideBar={() => setSideBarOpen(true)}
          actionButton={actionButton}
        />

        <div className="relative h-full overflow-auto">{outlet}</div>
      </div>
      <NewNoteModal
        show={newNoteModalOpen}
        onClose={toggleNewNoteModal}
        patientId={patientId}
      />
    </>
  );
};
