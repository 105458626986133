import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import {
  CancelSubmitButtons,
  ErrorText,
  Switch,
  TransitionEffect,
} from "~/common/components";
import Modal from "~/common/components/Modal";
import TextArea from "~/common/components/ui/Textarea";
import { PatientMedication } from "~/common/types";
import { formatMedicationName } from "~/common/utils";
import { TEXT_MEDIUM_LENGTH } from "~/providers/constants";
import { usePauseMedicationScript } from "~/providers/hooks";
import {
  formatScriptDescription,
  getModifiedScripts,
  PauseScriptFormValuesType,
  pauseScriptSchema,
} from "~/providers/utils";

interface PrescriptionPermissionModalProps {
  show: boolean;
  onClose: () => void;
  medications: PatientMedication[];
  patientId: string;
}

export const PrescriptionPermissionModal = ({
  show,
  onClose,
  medications,
  patientId,
}: PrescriptionPermissionModalProps) => {
  const defaultMedicationsValues = medications.map(
    ({ id, scripts_statuses }) => ({
      paused: !!scripts_statuses?.[0]?.is_paused,
      id: id,
    }),
  );

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<PauseScriptFormValuesType>({
    resolver: zodResolver(pauseScriptSchema),
    defaultValues: {
      patient_medications: defaultMedicationsValues,
    },
  });

  const handleClose = () => {
    onClose();
    reset();
  };
  const { pauseMedicationScriptMutation, isPending } = usePauseMedicationScript(
    patientId,
    handleClose,
  );

  const onSubmit = (data: PauseScriptFormValuesType) => {
    pauseMedicationScriptMutation(data);
  };

  const modifiedMedications = getModifiedScripts(
    defaultMedicationsValues,
    watch("patient_medications"),
  );

  return (
    <Modal
      show={show}
      onClose={handleClose}
      title="Prescription permissions"
      description="Pause prescriptions for current medications."
      className="top-18 fixed md:w-2/5"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col items-end gap-4 transition-all"
      >
        <div className="flex w-full flex-col gap-4">
          <div className="flex flex-col gap-3">
            {medications.map((medication, index) => (
              <Switch
                id={medication.id}
                key={medication.id}
                {...register(`patient_medications.${index}.paused`)}
              >
                Pause {formatMedicationName(medication.medication)}
              </Switch>
            ))}
          </div>
          <TransitionEffect show={!!modifiedMedications.length}>
            <TextArea
              id="internal_note"
              label="Internal note"
              {...register("internal_note")}
              maxCharacters={TEXT_MEDIUM_LENGTH}
              charactersCount={watch("internal_note")?.length}
            >
              You are modifying{" "}
              <span className="font-medium">
                {formatScriptDescription(medications, modifiedMedications)}
              </span>
            </TextArea>
          </TransitionEffect>
        </div>
        <div className="flex w-full flex-col items-end justify-end gap-2">
          <ErrorText>{errors.internal_note?.message}</ErrorText>
          <CancelSubmitButtons
            submitButtonLabel="Save changes"
            variant="right-outline-black"
            containerClassName="w-2/3"
            handleCancel={handleClose}
            isSubmitting={isPending}
            disableSubmit={!modifiedMedications.length}
          />
        </div>
      </form>
    </Modal>
  );
};
