import { CHECK_IN_CODES, CHECKIN_STATUS } from "~/common/utils";
import { RELATED_MODEL } from "~/providers/constants";
import { PatientFeed } from "~/providers/utils/types/interactions";
import { CheckInSubmittedFeed } from "~/providers/utils/types/patientFeeds";
import { FeedCompletedCheckinAnswers } from "./FeedCompletedCheckinAnswers";
import { InternalNote } from "./InternalNote";

interface RenderFeedCardChildrenProps {
  feedOption: PatientFeed;
  internalNote?: string;
}
export const RenderFeedCardChildren = ({
  feedOption,
  internalNote,
}: RenderFeedCardChildrenProps) => {
  if (
    RELATED_MODEL.patient_checkin === feedOption.related_model_type &&
    feedOption.context.status === CHECKIN_STATUS.completed &&
    feedOption.context.checkin_code !== CHECK_IN_CODES.schedule_appointment
  )
    return (
      <FeedCompletedCheckinAnswers
        answers={(feedOption as CheckInSubmittedFeed).context.data.answers}
        checkInCode={feedOption.context.checkin_code}
      />
    );

  if (internalNote) return <InternalNote text={internalNote} />;

  return null;
};
