import { useParams } from "react-router-dom";

import { ActivityFeed } from "~/providers/components/patientProfile/ActivityFeed";
import { PatientParams } from "~/providers/utils";

export const LeadPatientActivityFeed = () => {
  const { patientId } = useParams<keyof PatientParams>() as PatientParams;

  return <ActivityFeed patientId={patientId} />;
};
